import _, { wrap } from 'lodash';
import { useEffect, useReducer, useRef, useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  getAllManagePrograms,
  updateProgramStatus,
  updateProgram,
  addManageProgram,
} from '../../../http/requests';

import styles from './manage-program-modal.scss';
import './manage-program-modal.css';

export const ManageProgramModal = (props: any) => {
  const globalProgramEdit = useRef('');
  const [programList, setProgramList] = useState([]);
  const [tableKey, setTableKey] = useState(0);
  const [editProgram, setEditProgram] = useState({});
  const [formError, setFormError] = useState({ programName: '' });
  const [defaultSorted, setDefaultSorted] = useState([{ dataField: 'programName', order: 'asc' }]);
  const [addProgramFlag, setAddProgramFlag] = useState(false);
  const [addProgramData, setAddProgramData] = useState({ programName: '', category: false });
  const [addFormError, setAddFormError] = useState({ programName: '' });

  useEffect(() => {
    if (props.show) {
      fetchPrograms();
      setEditProgram({});
      setAddProgramFlag(false);
      globalProgramEdit.current = '';
      setFormError({ programName: '' });
      setTableKey(0);
      setDefaultSorted([{ dataField: 'programName', order: 'asc' }]);
    }
  }, [props.show]);

  const fetchPrograms = () => {
    getAllManagePrograms().then((res) => setProgramList(res));
  };

  const updateAvtiveStatus = (row) => {
    const rowIndex = programList.findIndex((obj) => obj.id === row.id);
    if (rowIndex !== -1) {
      const updatedArray = [...programList];
      updatedArray[rowIndex].active = !updatedArray[rowIndex].active;
      setProgramList(updatedArray);
      setTableKey(tableKey + 1);
    }
  };

  const handleToggle = (row: any) => {
    updateAvtiveStatus(row);
    const payload = { id: row.id, active: row.active };
    updateProgramStatus(payload).catch((err) => {
      console.log(err);
      updateAvtiveStatus(row);
    });
  };

  const editAction = (row: any) => {
    setEditProgram({ id: row.id });
    setFormError({ programName: '' });
    globalProgramEdit.current = row.programName;
    setTableKey(tableKey + 1);
  };

  const programExist = (program, action) => {
    if (action === 'edit') {
      return programList.some(
        (obj) =>
          obj.id !== editProgram.id && obj.programName.toLowerCase() === program.toLowerCase()
      );
    } else if (action === 'add') {
      return programList.some((obj) => obj.programName.toLowerCase() === program.toLowerCase());
    }
  };

  const programValidation = (program, action, programFormError) => {
    if (!program.trim()) programFormError({ programName: 'This is a required field' });
    else if (programExist(program, action))
      programFormError({ programName: 'Program already exists' });
    else {
      programFormError({ programName: '' });
      return false;
    }
    return true;
  };

  const programEditAction = (row: any, action: string | null = null) => {
    if (action === 'confirm') {
      if (programValidation(globalProgramEdit.current, 'edit', setFormError)) {
        setTableKey(tableKey + 1);
        globalProgramEdit.current = row.programName;
        return;
      }
      if (globalProgramEdit.current !== row.programName) {
        const payload = { id: row.id, programName: globalProgramEdit.current.trim() };
        updateProgram(payload)
          .then((res) => {
            fetchPrograms();
          })
          .catch((err) => {
            console.log(err);
            fetchPrograms();
          });
      }
    }
    setEditProgram({});
    globalProgramEdit.current = '';
    setTableKey(tableKey + 1);
  };

  const onProgramChange = (e) => {
    globalProgramEdit.current = e.target.value;
    if(formError.programName){
      setFormError({programName: ""});
    }
  }

  const handleSortClick = (sortOrder) => {
    setDefaultSorted([{ dataField: 'programName', order: sortOrder === 'asc' ? 'desc' : 'asc' }]);
  };

  const SortCaret = ({ order, column }) => {
    // You can access order and column here
    return (
      <span>
        &nbsp;
        <img
          style={{ cursor: 'pointer' }}
          src={require('../../../common/images/sort_asc.png')}
          alt=""
          onClick={() => handleSortClick(order)}
        />
        <img
          style={{ cursor: 'pointer' }}
          src={require('../../../common/images/sort_desc.png')}
          alt=""
          onClick={() => handleSortClick(order)}
        />
      </span>
    );
  };

  const columns = [
    {
        dataField: 'programName',
        text: 'PROGRAM',
        sort: true,
        key: 'programNameColumn',
        formatExtraData: formError,
        formatter: (cell, row, rowIndex, formatExtraData) => (
            <>
                {
                    row.id === editProgram.id? (
                        <>
                        <Form.Group controlId="formBasicItem">
                            <Form.Control
                                type="text"
                                maxLength={100}
                                defaultValue={cell}
                                onChange={onProgramChange}
                                style={{ height: '30px', width: '75%' }}
                            />
                            {
                              formatExtraData.programName ? <span className={styles.error}>{formatExtraData.programName}</span> : ''
                            }
                        </Form.Group>
                        </>
                    ) : (
                        <div style={!row.active? { color: '#CECED6'} : {}}>{row.programName}</div>
                    )
                }
            </>
        ),
        sort: true,
        sortCaret: (order, column) => (
          <SortCaret order={order} column={column} />
        ),
        style: { width: '24rem' },
    },
    {
      dataField: '',
      text: 'ACTIONS',
      formatter: (cell, row) => (
        <>
          {row.id === editProgram.id ? (
            <div className={styles.action_col}>
              <img
                style={{ cursor: 'pointer' }}
                src={require('../../../common/images/cancel.png')}
                alt=""
                onClick={() => programEditAction(row)}
              />
              <img
                style={{ cursor: 'pointer' }}
                src={require('../../../common/images/confirm.png')}
                alt=""
                onClick={() => programEditAction(row, 'confirm')}
              />
            </div>
          ) : (
            <div className={styles.action_col}>
              <img
                style={row.active ? { cursor: 'pointer' } : {}}
                src={
                  row.active
                    ? require('../../../common/images/edit-primary.png')
                    : require('../../../common/images/disabled-edit.png')
                }
                alt=""
                onClick={(e) => (row.active ? editAction(row, e) : {})}
              />
              <Form className={styles.action_switch}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={row.active}
                  onChange={() => handleToggle(row)}
                />
              </Form>
            </div>
          )}
        </>
      ),
      style: { width: '8rem' },
    },
  ];

  const addProgramModal = () => {
    if (!addProgramFlag) {
      setAddProgramData({ programName: '', category: false });
      setAddFormError({ programName: '' });
      setAddProgramFlag(true);
    }
  };

  const onAddProgramChange = (e, field) => {
    if (addFormError.programName && field === 'program') {
      setAddFormError({ programName: '' });
    }
    setAddProgramData((obj) => ({
      ...obj,
      [field === 'program' ? 'programName' : field === 'category' ? 'category' : '']:
        field === 'category' ? !obj.category : e.target.value,
    }));
  };

  const addProgramAction = (flag) => {
    if (flag) {
      if (programValidation(addProgramData.programName, 'add', setAddFormError)) {
        return;
      }
      const payload = {
        programName: addProgramData.programName.trim(),
        category: addProgramData.category,
      };
      addManageProgram(payload).then((res) => {
        fetchPrograms();
        setAddProgramFlag(false);
      });
    } else {
      setAddProgramFlag(false);
    }
  };

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          <span>Manage Program</span>
          {addProgramFlag ? (
            <img
              src={require('../../../common/images/activeAddButton.png')}
              alt="add button"
              className={styles.add_Button}
            />
          ) : (
            <img
              src={require('../../../common/images/addButton.png')}
              alt="add button"
              className={styles.add_Button}
              onClick={addProgramModal}
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addProgramFlag ? (
          <div className={styles.add_modal}>
            <div className={styles.add_fields}>
              <div style={{ display: 'flex', flexWrap: 'nowrap', paddingBottom: '0.5rem' }}>
                <div className={styles.field_name}>
                  Program Name<span className={styles.astrix}>*</span>
                </div>
                <Form.Group controlId="formBasicItem">
                  <Form.Control
                    type="text"
                    maxLength={100}
                    onChange={(e) => onAddProgramChange(e, 'program')}
                    style={{ height: '30px', width: '250px' }}
                  />
                  {addFormError.programName ? (
                    <span className={styles.error}>{addFormError.programName}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              <div style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: '0.5rem' }}>
                <div className={styles.field_name}>Category</div>
                <Form style={{ fontSize: '18px' }} className={styles.action_switch}>
                  <Form.Check
                    type="switch"
                    id="custom-add-switch"
                    checked={addProgramData.category}
                    onChange={(e) => onAddProgramChange(e, 'category')}
                  />
                </Form>
              </div>
            </div>
            <div className={styles.add_action}>
              <img
                style={{ cursor: 'pointer' }}
                src={require('../../../common/images/cancel.png')}
                alt=""
                onClick={() => addProgramAction(false)}
              />
              <img
                style={{ cursor: 'pointer' }}
                src={require('../../../common/images/confirm.png')}
                alt=""
                onClick={() => addProgramAction(true)}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.table}>
          <BootstrapTable
            key={tableKey}
            bootstrap5
            bordered={false}
            trClassName={styles.customRow}
            keyField="id"
            data={programList}
            columns={columns}
            defaultSorted={defaultSorted}
            headerClasses={styles.fixed_header}
          />
        </div>
      </Modal.Body>
      <Modal.Footer bsPrefix={styles.footer}>
        <div>
          <Button onClick={props.onHide} className={styles.cancel} variant="secondary">
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
