import _ from 'lodash';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import {
  useEffect, useReducer, useRef, useState 
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup 
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { getClientList, getClientName, getActiveUsersList } from '../../../http/requests';

import styles from './client-modal.scss';

import globalStyle from '../../../common/styles/globalStyle.scss';



import './client.css';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

export const ClientModal = (props: any) => {
  const clientInitialData = {
    clientName: '',
    clientPartnerList: [],
    dateCreated: '',
    logo: '',
  };

  // const intialnameList:any = [];

  const initialFormError: {
    clientName: string,
    clientPartnerList: string,
    logo: any
  } = {
    clientName: '',
    clientPartnerList: '',
    logo: {
      type: false,
      size: false,
      dimension: false,
      required: false,
    },
  };

  const clientDataReducer = (state: any, action: any) => {
        switch (action.type) {
      case 'Add_Client':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [ uploadedFileName, setUploadedFileName ] = useState<string | null>(null);
  const [ inputFile, setInputFile ] = useState<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [ hideOptions, setHideOptions ] = useState(true);
  const initialClientName: any = null;
  const [ selectedClientName, setSelectedClientName ] = useState(initialClientName);

  const [ hideselectedValue, setSelectedValue ] = useState(true);

  const locationFilterInput = useRef(null);

  const [ ownerSelectedValue, setOwnerSelectedValue ] = useState([]);

  const [ filteredUser, setFilteredUser ] = useState([ ]);
  const [ departmentList, setDepartmentList ] = useState([]);
  // const [ nameList, setNameList ] = useState(intialnameList);
  // const [ _nameList, _setNameList ] = useState(intialnameList);
  const [ modalData, dispatchData ] = useReducer(clientDataReducer, clientInitialData);
  const [ formError, setFormError ] = useState(initialFormError);
  const [ fileTypeError, setFileTypeError ] = useState('');
  const [userKey, setUserKey] = useState(0);

  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

    useEffect(()=>{
      if(props.activeTab==='client'){
        fetchAndSetUserData();
      }
    },[props.activeTab]);
  useEffect(() => {

    if(props.data.header && props.data.header !='View Client'){
      // getClientName().then((res)=>{
      //   setNameList(res);
      //   _setNameList(res);
      // });
      setInputFile(document.getElementById('input-file') as HTMLInputElement);
      fetchDepartmentList();
      fetchAndSetUserData();
    }
    
    if (props.data && props.data.currentRow) {
      if(props.data.currentRow.clientPartnerList.length===1 && props.data.currentRow.clientPartnerList[0].partnerAzureID==='00000000-0000-0000-0000-000000000000'){
        setOwnerSelectedValue([]);
        if(getModalType() == 'update'){
          setSelectedClientName({
            clientName: props.data.currentRow.clientName,
            clientID: props.data.currentRow.id,
          });
        
          dispatchData({
            type: 'Add_Client',
            payload: {
              clientName: props.data.currentRow.clientName,
              clientPartnerList: '',
              dateCreated: moment().format('MM-DD-YYYY'),
              logo: props.data.currentRow.logo,
            },
          });
          setSelectedValue(false);
        } else{
          setSelectedClientName({ clientID: null, clientName: ""});
          dispatchData({
            type: 'Add_Client',
            payload: {
              clientName: '',
              clientPartnerList: '',
              dateCreated: moment().format('MM-DD-YYYY'),
              logo: props.data.currentRow.logo,
            },
          });
          setSelectedValue(true);
        }
      }
      else{
        const dep_owner = props.data.currentRow.clientPartnerList;
        
        const selected: any = [];
        dep_owner.forEach((d:any)=>{
          selected.push({
            ...d,
            name:d.partnerName,
            nameEmail: `${d.partnerName}|(${d.userEmail})`,
          });
        });
        setOwnerSelectedValue(selected);
        if(getModalType() == 'update'){
          setSelectedClientName({
            clientName: props.data.currentRow.clientName,
            clientID: props.data.currentRow.id,
          });      
          dispatchData({
            type: 'Add_Client',
            payload: {
              clientName: props.data.currentRow.clientName,
              clientPartnerList: props.data.currentRow.clientPartnerList,
              dateCreated: moment().format('MM-DD-YYYY'),
              logo: props.data.currentRow.logo,
            },
          });
          setSelectedValue(false);
        } else{
          setSelectedClientName({ clientID: null, clientName: ""});
          dispatchData({
            type: 'Add_Client',
            payload: {
              clientName: '',
              clientPartnerList: props.data.currentRow.clientPartnerList,
              dateCreated: moment().format('MM-DD-YYYY'),
              logo: props.data.currentRow.logo,
            },
          });
          setSelectedValue(true);
        }
      }
    } else {
      setSelectedClientName({ clientID: null, clientName: ""});
      setOwnerSelectedValue([]);
      dispatchData({
        type: 'Add_Client',
        payload: {
          clientName: '',
          clientPartnerList: '',
          dateCreated: moment().format('MM-DD-YYYY'),
          logo: '',
        },
      });
    }
    return () => {
      setUploadedFileName('');
      removeSelectedClientName();
      setFileTypeError('');
      setFormError((p: any) => 
        ({
          clientPartnerList: '',
          clientName: '',
          clientID: '',
          logo: {
            type: false,
            size: false,
            dimension: false,
          },
        }));
      dispatchData({
        type: 'Add_Client',
        payload: {
          clientName: '',
          clientPartnerList: '',
          dateCreated: moment().format('MM-DD-YYYY'),
          logo: '',
        },
      });
    };
  }, [ props.data]);


  const fetchAndSetUserData = () => {
    const filteredData: any = [];
    if(role=='Super Admin'){
      getActiveUsersList().then((res:any) => {
        if(filteredUser.length===0 && locationFilterInput.current){
          if (locationFilterInput.current.searchBox.current.value) {
            setUserKey(prevKey => prevKey + 1);
          }
        }
        res.map((user: any, id: any) => {
          if (user.firstName) {
            if(user.lastName){
              filteredData.push({
                nameEmail: `${user.firstName} ${user.lastName}|(${user.email})`,
                ...user,
              });
            }
            else{
              filteredData.push({
                nameEmail: `${user.firstName} |(${user.email})`,
                ...user,
              });
            }
          }
        });
        setFilteredUser(filteredData);
      });
    }
  };


  function onSelect(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];

    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        clientPartnerList: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      if (s && s.partnerAzureID) {
        data.push(s);
      } else {
        data.push({
          partnerAzureID: s.id,
          partnerName: s.firstName + ' '+(s.lastName?s.lastName:''),
          userEmail: s.email,
          userType: s.userType,
        });
      }
    });
    dispatchData({
      type: 'Add_Client',
      payload: { clientPartnerList: data },
    });
  }

  function onRemove(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          clientPartnerList: 'Client Partner required',
        }));
      dispatchData({
        type: 'Add_Client',
        payload: { clientPartnerList: [] },
      });
    } else {
      dispatchData({
        type: 'Add_Client',
        payload: { clientPartnerList: selectedList },
      });
    }
  }

  const getModalType = () => {
    if (props.data.header === 'Add Client') {
      return 'add';
    } else if (props.data.header === 'Edit Client') {
      return 'update';
    } else if (props.data.header === 'Copy Client') {
      return 'copy';
    }
  };

  const fetchDepartmentList = () => {
    getClientList(role).then((res) => {
      setDepartmentList(_.cloneDeep(res));
    }).catch((err)=>{
      console.log(err);
    });
  };

  const validateForm = (modalData: any) => {
    let isValid = true;

    if ( !modalData.clientName) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          clientName: 'Client name required',
        }));
    } else {
      const modalType = getModalType();
      
      let exist = false;
      departmentList.forEach((d: any) => {
        if (d.clientName.toLowerCase() === modalData.clientName.toLowerCase()) {
          exist = true;
          if (props.data && props.data.currentRow && (props.data.currentRow.clientName.toLowerCase() === modalData.clientName.toLowerCase()) && modalType == 'update') {
            exist = false;
          }
        }
      });
      if (exist) {
        setFormError((p: any) => 
          ({
            ...p,
            clientName: 'Client name already exist',
          }));
        isValid = false;
      } else {
        setFormError((p: any) => 
          ({
            ...p,
            clientName: '',
          }));
      }
      // }
      if (modalData.clientName.length > 100) {
        setFormError((p: any) => 
          ({
            ...p,
            clientName: 'Length should be less then 100',
          }));
        isValid = false;
      }
    }
    if (modalData.clientPartnerList.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          clientPartnerList: 'Client partner required',
        }));
    }

    if (modalData.logo.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          logo: {
            ...p.logo,
            required: true,
          },
        }));
    }

    return isValid;
  };

  const submit = () => {
    const trimmedname = modalData.clientName.trim();
    const data=modalData;
    data.clientName=trimmedname;

    dispatchData({
      type: 'Add_Client',
        payload: { clientName: trimmedname },  
    })
    setSelectedClientName((p: any) => 
    ({
      ...p,
      clientName: trimmedname,
    }));


    if (validateForm(data)) {
      // delete modalData.clientName;
      if (props.data && props.data.currentRow) {
        props.data.submit({
          modalData:data,
          id: props.data.currentRow.id,
          type: getModalType(),
          loading: false,
        });
      } else {
        props.data.submit({
          modalData:data,
          type: getModalType(),
          loading: false,
        });
      }
    }
  };

  function transformDate(date: any) {
    const a = date.split('-');
    return `${a[2].slice(0, 2)}/${a[1]}/${a[0]}`;
  }

  const getFullName = (user: any) => 
    user.firstName + ' ' + user.lastName;

  const selectedValueDecorator = (val: any) => 
    (
      <small>{val.split('|')[0]}</small>
    );
  

  const optionValueDecorator = (val: any) => 
    (
      val?<p>{val.replace('|', ' ')}</p>:<p></p>
    );
  

  function getBase64(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      //Initiate the JavaScript Image object.
      const image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = image.height;
        const width = image.width;

        if (height > 200 || width > 200) {
          if (inputRef.current instanceof HTMLInputElement) {
            inputRef.current.value = '';
          }
          setFormError((p: any) => 
            ({
              ...p,
              logo: {
                ...p.logo,
                dimension: true,
              },
            }));
          // alert("Height and Width must not exceed 100px.");
          return false;
        }
        
        dispatchData({
          type: 'Add_Client',
          payload: { logo: reader.result },
        });
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
        return true;
      };
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  const handleUpload = (e: any) => {
    e.preventDefault();
    setUploadedFileName('');
    setFormError((p: any) => 
      ({
        ...p,
        logo: {
          type: false,
          size: false,
          dimension: false,
        },
      }));
      setFileTypeError('')
      
    dispatchData({
      type: "Add_Client",
      payload: {
        logo: '',
      }
    })
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    const filePath: string = inputRef?.current?.value || '';

    // Allowing file type
    const allowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (filePath && !allowedExtensions.exec(filePath)) {
      if (inputRef.current instanceof HTMLInputElement) {
        inputRef.current.value = '';
      }
      setFormError((p: any) => 
        ({
          ...p,
          logo: {
            type: true,
            ...p.logo,
          },
        })
       
      );
      setFileTypeError('Please attach a valid file type');
      return false;
    }
    else {
      setFileTypeError('');
      const size = +(inputRef?.current?.files[0].size / (1024 * 1024)).toFixed(2);

      if (size > 1) {
        if (inputRef.current instanceof HTMLInputElement) {
          inputRef.current.value = '';
        }
        setFormError((p: any) => 
          ({
            ...p,
            logo: {
              ...p.logo,
              size: true,
            },
          }));
      }

     
      if (inputRef && inputRef.current && inputRef.current.files) {
        const file = inputRef.current.files[0];
        getBase64(file);
      }
    }
  };


  const removeSelectedClientName = () => {
    setSelectedClientName(null);
  };

  function customArrow() {
    return (
      <div>
        <img
          src={require('../../../common/icons/arrow-down.svg')}
        />
      </div>

    );
  }
  return (
        <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.data.header == 'View Client' ?
            (
              <>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>Client Name</div>
                    <div className={styles.view_department}>{props.data.currentRow.clientName}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Client Partner</div>
                    <div className={styles.view_owners}>{
                      props.data.currentRow.clientPartnerList.map((d:any)=>
                        <p key={d.partnerAzureID}>{d.partnerName} &nbsp;</p>)
                    }</div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xs={6}>
                    <div className={styles.department_heading}>Date Created</div>
                    <div className={styles.view_department}>{transformDate(props.data.currentRow.dateCreated)}</div>
                  </Col>
                  <Col xs={6}>
                    <div className={styles.department_heading}>Client Logo</div>
                    <div >
                      <img className={styles.client_logo} src={props.data.currentRow.logo} alt="" />
                    </div>

                  </Col>
                </Row>
              </>
            ) :
            (
              <Form>
                <Row className="mt-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label className={styles.department_heading}>Client Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <div className={styles.LocationBar}>
                        
                        <Form.Control
                          className={styles.LocationBarFormControl}
                          type="text"
                          value={selectedClientName?.clientName}
                          onChange={(e) => {
                            setSelectedClientName({clientID: null, clientName: e.target.value});
                            dispatchData({
                              type: 'Add_Client',
                              payload: { clientName: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                clientName: '',
                              }));
                          }}
                        
                        />
                        
                        
                      </div>
                      {
                        formError.clientName ? <span className={styles.error}>{formError.clientName}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col >

                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label className={styles.department_heading}>Date Created</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className={styles.date_created}
                          disabled
                          type="text"
                          value={moment().format('MM/DD/YYYY')}
                        />
                        <InputGroup.Text className={styles.calendar}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label className={styles.department_heading}>Client Partner
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <Multiselect
                        key={userKey}
                        ref={locationFilterInput}
                        customArrow={customArrow()}
                        options={filteredUser} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={ownerSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="nameEmail" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        loading={filteredUser.length===0?true:false}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                      {
                        formError.clientPartnerList ? <span className={styles.error}>{formError.clientPartnerList}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label className={styles.department_heading}>Client Logo
                      <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.upload}>
                        {
                          (getModalType() == 'update' || getModalType() == 'copy') ?
                          (
                            <div className={styles.edit_logo}>
                              <div>
                                <img className={styles.client_logo} src={modalData.logo} alt="" />
                              </div>
                              <div className={styles.edit_logo_btn}>
                                <input
                                  ref={inputRef}
                                  onChange={handleDisplayFileDetails}
                                  className="d-none"
                                  type="file"
                                />
                                <Button type="button" onClick={handleUpload} className={styles.edit_logo_btn_main} variant="secondary">
                                  Change
                                </Button>
                              </div>
                            </div>
                          ) :
                          (
                            <>
                              <label style={{ flex: '1', alignSelf: 'center' }} className="mx-3">{uploadedFileName ? uploadedFileName : 'Browse File'}</label>
                              <input
                                ref={inputRef}
                                onChange={handleDisplayFileDetails}
                                className="d-none"
                                type="file"
                              />
                              <button
                                type="button"
                                className={`ounded-0 ${styles.mybtn}`}
                                onClick={handleUpload}
                              >
                                Select
                              </button>
                            </>
                          )
                        }
                        
                      </div>
                    </Form.Group>
                    <div className={styles.file_warning}>
                      Maximum size allowed 1 MB. .png, .jpg/jpeg allowed. size of 200 x 200 pixels
                    </div>
                    {
                      formError.logo.required ? <p className={styles.error}>Client logo required</p> : null
                    }
                    {
                      formError.logo.size ? <p className={styles.error}>Maximum size limit exceeded (1MB)</p> : null
                    }
                    {
                      formError.logo.dimension ? <p className={styles.error}>Logo should be 200 x 200 pixels</p> : null
                    }
                    {
                      formError.logo.type ? <p className={styles.error}>Incorrect file type</p> : null
                    }
                    {
                      fileTypeError ? <p className={styles.error}>{fileTypeError}</p> : null
                    }
                    
                  </Col>
                <Col />
                </Row>
              </Form>
            )
        }

      </Modal.Body>
      {
        (props.data.header != 'View Client') ?
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              </div>
              <div>
                <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                  {getModalType() == 'add' ? 'Create' : getModalType() == 'update' ? 'Update' : 'Copy'}
                </Button>
              </div>
            </Modal.Footer>
) :
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">
                  <img src={require('../../../common/images/back_arrow.png')} alt="" /> &nbsp;Back</Button>
              </div>
            </Modal.Footer>
          )
      }
    </Modal>
      );
};

