import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { USER_ROLE } from '@constants';

import Announcment from './announcment/announcment';
import Client from './client/client';
import Department from './department/department';
import Program from './program/program';
import Report from './report/report';
import styles from './settings.scss';
import User from './user/user';
import { setUserRole } from '@redux/actions';
import { store } from '@redux/store';
import { getLoginRecord } from '@http/requests';
import { getLocalStorageItem, setLocalStorageItem } from '@utils';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

export const Settings = () => {
  const [ activeKey, setActiveKey ] = useState('client');
  const [ roleChange, setRoleChange ] = useState(false);

  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ loading, setLoading ] = useState(true);


  const role: string = useSelector(({ USER }: RootState) =>
    USER.userRole) || 'User';

  useEffect(()=>{
    setIsAdmin(role == 'Super Admin'? true : false);
  },[role]);


  useEffect(() => {
    //const role: string = localStorage.getItem(USER_ROLE) || 'User';
    setIsAdmin((role == 'Super Admin') ? true : false);
    const account = JSON.parse(localStorage.getItem('account'));
    if (account && account.localAccountId) {
      getLoginRecord({ userId: account.localAccountId }).then((res: any) => {
        if(res && res.userRoleName && res.userRoleName!=role){
          store.dispatch(setUserRole(res.userRoleName));
          setLocalStorageItem(USER_ROLE, res.userRoleName);
          setRoleChange((prevroleChange) => !prevroleChange);
            // if(role === 'Super Admin' || res.userRoleName==='Super Admin'){
            //   window.location.reload();
            // }else{
            //   store.dispatch(setUserRole(res.userRoleName));
            //   setLocalStorageItem(USER_ROLE, res.userRoleName);
            //   setRoleChange((prevroleChange) => !prevroleChange);
            // }
        }
      })
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  },[activeKey]);
  return (
    <div className={styles.settings}>
      {
        !loading ? (
          <Tab.Container id="left-tabs-example" defaultActiveKey="client">
            <div className={styles.sections}>
              <Nav className={`${styles.sections}`}>
                <Nav.Item className={`${styles.section} ${activeKey === 'client' ? styles.active : ''}`}>
                  <Nav.Link
                    className="element"
                    onClick={() =>
                      setActiveKey('client')}
                    eventKey="client"
                  >Client</Nav.Link>
                </Nav.Item>
                <Nav.Item className={`${styles.section} ${activeKey === 'program' ? styles.active : ''}`}>
                  <Nav.Link
                    onClick={() =>
                      setActiveKey('program')}
                    eventKey="program"
                  >Program</Nav.Link>
                </Nav.Item>
                <Nav.Item className={`${styles.section} ${activeKey === 'department' ? styles.active : ''}`}>
                  <Nav.Link
                    onClick={() =>
                      setActiveKey('department')}
                    eventKey="department"
                  >Department</Nav.Link>
                </Nav.Item>
                <Nav.Item className={`${styles.section} ${activeKey === 'report' ? styles.active : ''}`}>
                  <Nav.Link
                    onClick={() =>
                      setActiveKey('report')}
                    eventKey="report"
                  >Report</Nav.Link>
                </Nav.Item>
                {
                  isAdmin ? (
                    <Nav.Item className={`${styles.section} ${activeKey === 'announcment' ? styles.active : ''}`}>
                      <Nav.Link
                        onClick={() =>
                        setActiveKey('announcment')}
                        eventKey="announcment"
                      >Announcement</Nav.Link>
                    </Nav.Item>
                ) : null
                }
                {
                  isAdmin && (
                    <Nav.Item className={`${styles.section} ${activeKey === 'user' ? styles.active : ''}`}>
                      <Nav.Link
                        onClick={() =>
                          setActiveKey('user')}
                        eventKey="user"
                      >User</Nav.Link>
                    </Nav.Item>
                  )
                }
              </Nav>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane eventKey="client">
                  <Client activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>
                <Tab.Pane eventKey="program">
                  <Program activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>
                <Tab.Pane eventKey="department">
                  <Department activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>
                <Tab.Pane eventKey="report">
                  <Report activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>
                {isAdmin &&(<Tab.Pane eventKey="user">
                  <User activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>)}
                <Tab.Pane eventKey="announcment">
                  <Announcment activeTab={activeKey} roleChange={roleChange}/>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        )
          : <div className="spinner" />
      }

    </div>
  );
};
