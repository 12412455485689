import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAnnouncements } from '@http/requests';

import styles from './announcement.scss';

export function Announcement(props:any) {
  const [ showRead, setShowRead ] = useState(false);
  const [ hide, setHide ] = useState(false);
  const [ announcement, setAnnouncement ] = useState('');
  const state = useSelector(state=>
    state);
  useEffect(()=>{
    getAnnouncements().then((res)=>{      
      setAnnouncement(res);
      setTimeout(() => {
        if(res.length > 100){
          setShowRead(true);
          setHide(false);
        } else{
          setHide(true);
        }
      }, 1000);
    })
      .catch((err)=>{
        setHide(true);
      });
  }, [ state.ANNOUNCMENT.announcment ]);
  const readMore = ()=>{
    setShowRead(false);
  };
  const showLess = ()=>{
    setShowRead(true);
  };
  return (
    announcement ? (
      <div className={showRead ? styles.main : styles.main2}>
        <div>
          <img className={styles.icon} src={require('@icons/announcement.svg')} alt="" />
        </div>
        <div className={styles.bold}>
          Announcement
        </div>
        <div className={styles.bold}>
          - 
        </div>
        <div className={styles.text}>
          {
          hide ? announcement :
          (
            showRead ? 
              <>{announcement.substring(0, props.charLength)}...&nbsp;&nbsp; <a className={styles.read_more} onClick={readMore}>Read more</a></>
            : <> {announcement} &nbsp;&nbsp;<a className={styles.read_more} onClick={showLess}>Show less</a> </>
          )
        }
        
        </div>      
      </div>
    ) : null
  );
}
