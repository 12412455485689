import { Form, Row, Col, Button, InputGroup, Modal } from 'react-bootstrap';
import styles from './sftp-credential-user.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import Multiselect from 'multiselect-react-dropdown';
import { getActiveUsers } from '@http/requests';
import { useEffect, useReducer, useRef, useState } from 'react';
import { ConfirmationModal } from '@components/modals/confirmation-modal/confirmation-modal';
import { postSFTPUsersDetails } from '@http/requests/sftp';


export default function SftpCredentialUser(props: any) {
    const sftpInitialData = {
        hostURL:'',
        userName:'',
        password: '',
        users: []
      };

    const initialFormError: {
        userName: string,
        hostURL: string,
        password: string,
        users: string
      } = {
        userName: '',
        hostURL: '',
        password: '',
        users: '',
      };

    const sftpDataReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'Add_SFTP':
                return { ...state, ...action.payload };
            default:
                return state;
        }
    };

    const [ modalData, dispatchData ] = useReducer(sftpDataReducer, sftpInitialData);
    const [ formError, setFormError ] = useState(initialFormError);
    const [ filteredUser, setFilteredUser ] = useState([ ]);
    const locationFilterInput = useRef(null);
    const [userKey, setUserKey] = useState(0);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showSuccessMsg, setShowSuccessMsg ] = useState('');

    
    useEffect(()=>{
      if(props.activeKeyTab=="user"){
        clearData();
        fetchAndSetUserData();
      }
    },[props.activeKeyTab]);

    useEffect(()=>{
        fetchAndSetUserData();
    },[]);

    const clearData = () => {
      setFormError({
        userName: '',
        hostURL: '',
        users: '',
        password: '',
      });

      dispatchData({
        type: 'Add_SFTP',
        payload: sftpInitialData,
      });
      
      setUserKey(p=>p+1);
    };

    const passwordShow = () => {
      setShowPassword(p =>
        !p);
    };
  
  const onHideConfirmation = () =>
      setShowConfirmation(false);
  const handleSuccessClose = () =>
    setShowSuccess(false);

  
  const saveSFTP = (e: any) => {
      e.preventDefault();
      const data=modalData;
      data.userName= modalData.userName.trim();
      data.hostURL= modalData.hostURL.trim();
      data.password= modalData.password.trim();
  
      dispatchData({
        type: 'Add_SFTP',
          payload: data,
      });
  
  
      if (validateForm(data)) {
          setShowConfirmation(true);
      }
  };

  const submit= () => {
      setShowConfirmation(false);
      postSFTPUsersDetails(modalData).then((res)=>{
        setShowSuccess(true);
        setShowSuccessMsg('SFTP Details added Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
        }, 1000);
        props.setActiveEdit(false);
        setShowPassword(false);
      })
      .catch((error) => {
        // Handle the error here
        setShowPassword(false);
      });
      clearData();
  }

  const cancelButton= () => {
    if(modalData.userName || modalData.hostURL || modalData.password || modalData.users.length > 0){
      clearData();
    }
    else{
      props.setActiveKey(null);
    }   
  }

  const validateForm = (modalData: any) => {
      let isValid = true;
  
      if ( !modalData.userName) {
        isValid = false;
        setFormError((p: any) => 
          ({
            ...p,
            userName: 'User Name required',
          }));
      }

      if ( !modalData.hostURL) {
          isValid = false;
          setFormError((p: any) => 
            ({
              ...p,
              hostURL: 'Host Server Address required',
            }));
      }

      if ( !modalData.password) {
      isValid = false;
      setFormError((p: any) => 
          ({
          ...p,
          password: 'password required',
          }));
      }


      if (modalData.users.length <= 0) {
        isValid = false;
        setFormError((p: any) => 
          ({
            ...p,
            users: 'User required',
          }));
      }

      // var regex = /^sftp:\/\/(?:\S+:\S+@)?(?:\S+@)?(?:[\w.-]+)(?::\d+)?(?:\/?|\/\S+)$/;
      //   if( !formError.hostURL && !regex.test(modalData.hostURL.toLowerCase())){
      //       isValid = false;
      //       setFormError((p: any) => 
      //         ({
      //           ...p,
      //           hostURL: 'Invalid Host Server Address',
      //         }));
      //   }

        if(!formError.password && modalData.password.length>50){
            isValid = false;
            setFormError((p: any) => 
              ({
                ...p,
                password: 'Password entered is too long',
              }));
        }
  
      return isValid;
    };
    

    const fetchAndSetUserData = () => {
      if(props.isAdmin){
        const filteredData: any = [];
        getActiveUsers().then((res:any) => {
            if(filteredUser.length===0 && locationFilterInput.current){
              if (locationFilterInput.current.searchBox.current.value) {
                setUserKey(prevKey => prevKey + 1);
              }
            }
            res.map((user: any, id: any) => {
              if (user.firstName) {
                if(user.lastName){
                  filteredData.push({
                    nameEmail: `${user.firstName} ${user.lastName}|(${user.userEmail})`,
                    ...user,
                  });
                }
                else{
                  filteredData.push({
                    nameEmail: `${user.firstName} |(${user.userEmail})`,
                    ...user,
                  });
                }
              }
            });
            setFilteredUser(filteredData);
          });
      }
    };


    function onSelect(selectedList: any, selectedItem: any) {
        const element = document.getElementsByClassName('searchWrapper')[0];
        element.scrollTop = element.scrollHeight + 50;
        setFormError((p: any) => 
          ({
            ...p,
            users: '',
          }));
        
          const data: any = [];
          selectedList.forEach((r:any)=>{
            data.push(
              r.azureID
            );
          });
        
        dispatchData({
          type: 'Add_SFTP',
          payload: { users: data },
        });
    }
    
    function onRemove(selectedList: any, removedItem: any) {
        if (selectedList.length <= 0) {
            setFormError((p: any) => 
            ({
                ...p,
                users: 'User required',
            }));
            dispatchData({
            type: 'Add_SFTP',
            payload: { users: [] },
            });
        } else {
            dispatchData({
            type: 'Add_SFTP',
            payload: { users: selectedList },
            });
        }
    }

    function customArrow() {
        return (
          <div>
            <img
              src={require('../../../common/icons/arrow-down.svg')}
            />
          </div>
    
        );
      }


      const selectedValueDecorator = (val: any) => 
        (
          <small>{val.split('|')[0]}</small>
        );
      
    
      const optionValueDecorator = (val: any) => 
        (
          val?<p>{val.replace('|', ' ')}</p>:<p></p>
        );

  return (
    <>
        <div style={{margin: "2.5rem 1.5rem"}}>
            <Form>
                <Row className="mt-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicHostUser">
                      <Form.Label className={styles.field_heading}>Host Server Address
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <div className={styles.LocationBar}>
                        <Form.Control
                          className={styles.field}
                          type="text"
                          placeholder="Host server address"
                          value={modalData.hostURL}
                          onChange={(e) => {
                            dispatchData({
                              type: 'Add_SFTP',
                              payload: { hostURL: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                hostURL: '',
                              }));
                          }}
                        />
                        {
                        formError.hostURL ? <span className={styles.error}>{formError.hostURL}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicUserName">
                      <Form.Label className={styles.field_heading}>User Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <div >
                        <Form.Control
                          className={styles.field}
                          type="text"
                          placeholder="Enter a user name"
                          value={modalData.userName}
                          onChange={(e) => {
                            dispatchData({
                              type: 'Add_SFTP',
                              payload: { userName: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                userName: '',
                              }));
                          }}
                        />
                        {
                        formError.userName ? <span className={styles.error}>{formError.userName}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicPassUser">
                      <Form.Label className={styles.field_heading}>Password
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        <InputGroup>
                            <Form.Control
                              className={styles.password}
                              type= {showPassword?"text":"password"}
                              placeholder="Password"
                              value={modalData.password}
                              onFocus={() => setIsFocused(true)} 
                              onBlur={() => setIsFocused(false)}
                              onChange={(e) => {
                                  dispatchData({
                                  type: 'Add_SFTP',
                                  payload: { password: e.target.value },
                                  });
                                  setFormError(p => 
                                  ({
                                      ...p,
                                      password: '',
                                  }));
                              }}
                            />
                              <InputGroup.Text className={`${styles.eye} ${isFocused ? styles.focussed : ''}`}>
                                  {showPassword ? <img onClick={passwordShow} src={require('../../../common/icons/eye-off.svg')} alt="" /> :
                                  <img onClick={passwordShow} src={require('../../../common/icons/eye.svg')} alt="" />}
                              </InputGroup.Text>
                          </InputGroup>
                        {
                        formError.password ? <span className={styles.error}>{formError.password}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col  className="col-3 col-lg-4">
                    <Form.Group className="mb-3" controlId="formBasicUser">
                      <Form.Label className={styles.field_heading}>Users
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <Multiselect
                        key={userKey}
                        ref={locationFilterInput}
                        customArrow={customArrow()}
                        options={filteredUser} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        //selectedValues={ownerSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="nameEmail" // Property name to display in the dropdown options
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        loading={filteredUser.length===0?true:false}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                        {
                        formError.users ? <span className={styles.error}>{formError.users}</span> : ''
                        }
                    </Form.Group>
                  </Col>
                  <Col  className="col-9 col-lg-8">
                        <div className={styles.footer}>
                            <Button onClick={cancelButton} className={styles.footerCancel} variant="secondary">Cancel</Button>
                        
                            <Button  onClick={saveSFTP} className={styles.footerAdd_btn} variant="primary">
                            Add
                            </Button>
                        </div>
                  </Col>
                </Row>
            </Form>
        </div>

        <ConfirmationModal submit={submit} show={showConfirmation} onHide={onHideConfirmation} message="You want to add the SFTP Details?"/>
        <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
