import { FC, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';

import { Announcement } from '@components/announcement';
import { Header } from '@components/header/header';
import { Login } from '@components/login';
import { USER_ROLE } from '@constants';
import { useAzureADAuth } from '@hooks';
import { getLoginRecord } from '@http/requests';
import { setUserRole } from '@redux/actions';
import { store } from '@redux/store';
import { clearAllCookies } from '@utils'
import { useLocation } from 'react-router-dom';

import { Sidebar } from '../../pages/sidebar';

import styles from './auth.scss';

import './auth.css';

export type AuthRouteProps = RouteProps & {
  Component: FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  name: string;
}

export const AuthRoute = ({
  Component,
  path,
  children,
  exact = false,
  name,
}: AuthRouteProps) => {
  const { isAuthenticated, inProgress } = useAzureADAuth();
  const [ loading, setLoading ] = useState(true);
  const [ show, setShow ] = useState(false);
  const [ un, setUn ] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUrl = location.pathname;

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem('account'));
    if (account && account.localAccountId) {
      getLoginRecord({ userId: account.localAccountId }).then((res) => {
        if (res && res.userRoleName) {
          setUn(false);
          setLoading(false);
          store.dispatch(setUserRole(res.userRoleName));
          localStorage.setItem('userRole', res.userRoleName);
        } else {
          setLoading(false);
          setShow(true);
          localStorage.setItem('userRole', 'NA');
        }
      })
        .catch((err) => {
          setLoading(false);
          setShow(true);
          setUn(true);
          if (err.status === 404) {
            localStorage.setItem('PortalLogout', 'true');
            store.dispatch(setUserRole('NA'));
          }
          history.push('/logout');
        });
    } else if(inProgress == "none" ){
      originCheck();
    }

  }, [ isAuthenticated, inProgress, un ]);

  const originCheck = () => {
    if(window.location.href != window.location.origin +'/') {
      history.push("/");
    } else if(isAuthenticated) {
      setLoading(false);
      if(!un) setUn(true);
    }
  }

  const logout = () => {
    history.push('/logout');
    setTimeout(() => {
      setShow(false);
    }, 2500);
  };

  if (isAuthenticated && !un) {
    return (

      <>
        {
          loading ?
            <div className="spinner" />
            : (
              <div className={styles.root}>
                { currentUrl!=='/logout' ?<>
                  <div className={styles.left}>
                    <Sidebar />
                  </div>
                </>:null
                }
              
                <div className={styles.right}>
                  <Announcement charLength={100} />
                  <div style={{ margin: '0 1.5rem 0 1.5rem' }}>
                    {!loading ? (
                      <>
                        <Header heading={name} />
                        <Route
                          exact={exact}
                          path={path}
                          render={(props: RouteComponentProps) =>
                            <Component {...props}>{children}</Component>}
                        /></>
                  ) : null}
                  </div>

                </div>
              </div>
            )}


      </>


    );
  } else {
    return <Login />;
  }
};
