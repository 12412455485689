import _ from 'lodash';
import moment from 'moment';
import Multiselect2 from 'multiselect-react-dropdown';
import {
  useEffect, useReducer, useRef, useState
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { MultiSelect } from 'react-multi-select-component';

import {
  getUserById, getDepartmentList, getActiveUsersList, getClientProgram, getAllClients, getReportDropdown 
} from '../../../http/requests';

import styles from './user-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';

import './user.css';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

export const UserModal = (props: any) => {
  const [ selected, setSelected ] = useState([]);
  const reportRef = useRef();
  const initialData = {
    'azureID': '',
    'firstName': '',
    'lastName': '',
    'userEmail': '',
    'clientIdList': [],
    'reportIdList': [],
    'programIdList': [],
    'departmentIdList': [],
    'userRoleName': '',
    'userType': '',
    // 'dateCreated' : '',
  };

  const intialnameList: any = [];
  const initialFormError: {
    azureID: string,
    userEmail: string,
    userRoleName: string,
    departmentIdList: string,
    clientIdList: string,
    programIdList: any,
    reportIdList: any
  } = {
    azureID: '',
    userEmail: '',
    userRoleName: '',
    departmentIdList: '',
    clientIdList: '',
    programIdList: '',
    reportIdList: '',
  };

  const initialLoginHistory = {
    'loginAttempt': '',
    'loginCount': 0, 
  };

  const dataReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'Add_Modal_Data':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const [ hideOptions, setHideOptions ] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const initialClientName: any = null;
  const [ selectedClientName, setSelectedClientName ] = useState(initialClientName);

  const [ hideselectedValue, setSelectedValue ] = useState(true);

  const locationFilterInput = useRef<HTMLInputElement>(null);
  
  const [ clientSelectedValue, setClientSelectedValue ] = useState([]);
  const [ programSelectedValue, setProgramSelectedValue ] = useState([]);
  const [ departmentSelectedValue, setDepartmentSelectedValue ] = useState([]);
  const [ reportSelectedValue, setReportSelectedValue ] = useState([]);

  const [ clients, setClients ] = useState([]);
  const [ programs, setPrograms ] = useState([]);
  const [ departments, setDepartments ] = useState([]);
  const [ reports, setReports ] = useState([]);
  const [ clientIdList, setClientIdList ] = useState([]);
  const [ programIdList, setProgramIdList ] = useState([]);
  const [ reportIdList, setReportIdList ] = useState([]);
  const [ departmentIdList, setDepartmentList ] = useState([]);
  const [ nameList, setNameList ] = useState(intialnameList);
  const [ _nameList, _setNameList ] = useState(intialnameList);
  const [ modalData, dispatchData ] = useReducer(dataReducer, initialData);
  const [ formError, setFormError ] = useState(initialFormError);
  const [ userLoginHistory, setUserLoginHistory ] = useState(initialLoginHistory);
  const [ userSearchField, setUserSearch ] = useState('');
  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(()=>{
    if(props.activeTab==='user'){
      if(role=='Super Admin'){
      getActiveUsersList().then((res) => {
        _setNameList(res);
        if(locationFilterInput.current){
          if (locationFilterInput.current.value) {
            const copy = res;
            const search=locationFilterInput.current.value;
            const filtered = copy.filter((d: any) => {
              if (d && ((d.firstName && d.firstName.toLowerCase().includes(search.toLowerCase())) ||
                (d.lastName && d.lastName.toLowerCase().includes(search.toLowerCase())) ||
                (d.email && d.email.toLowerCase().includes(search.toLowerCase())))) {
                return d;
              }
            });
            setNameList(filtered);
          } else {
            setNameList(res);
          }
        }
        else{
          setNameList(res);
        }
      });
    }
    }
  },[props.activeTab]);

  useEffect(() => {
    if(role=='Super Admin'){
    getActiveUsersList().then((res) => {
      _setNameList(res);
      if(locationFilterInput.current){
        if (locationFilterInput.current.value) {
          const copy = res;
          const search=locationFilterInput.current.value;
          const filtered = copy.filter((d: any) => {
            if (d && ((d.firstName && d.firstName.toLowerCase().includes(search.toLowerCase())) ||
              (d.lastName && d.lastName.toLowerCase().includes(search.toLowerCase())) ||
              (d.email && d.email.toLowerCase().includes(search.toLowerCase())))) {
              return d;
            }
          });
          setNameList(filtered);
        } else {
          setNameList(res);
        }
      }
      else{
        setNameList(res);
      }
    });
  }

    if(props.data.header){
      getAllClients().then((res) => {
        const data: any = [];
        res.forEach((r: any) => {
          data.push({
            ...r,
            name: r.clientName,
          });
        });
        setClientIdList(data);
      });
      getDepartmentList(role, true).then((res) => {
        const data: any = [];
        res.forEach((r: any) => {
          data.push({
            ...r,
            name: r.departmentName,
          });
        });
        setDepartmentList(data);
      }).catch((err)=>{
        console.log(err);
      });

      getClientProgram().then((res) => {
        const data: any = [];
        res.forEach((r: any) => {
          data.push({
            ...r,
            name: r.clientName + ' - ' + r.programName,
          });
        });
        setProgramIdList(data);
      });

      getReportDropdown().then((res:any)=>{
        const data: any = [];
        res.forEach((r: any) => {
          data.push({
            ...r,
            label: r.departmentName + '-' + r.reportName,
            value: r.departmentReportID,
          });
        });
        setReportIdList(data);
      });
    }
    if (props.data && props.data.currentRow) {

      getUserById(props.data.currentRow.usersID).then((res: any) => {
        setUserLoginHistory(res.userLoginHistory);
        setSelectedClientName({
          'email': res.userDetails.userEmail,
          'id': res.userDetails.azureID,
          'firstName': res.userDetails.firstName,
          'lastName': res.userDetails.lastName,
        });
        setSelectedValue(false);
        setClients(res.clients);
        setPrograms(res.programs);
        setDepartments(res.departments);
        setReports(res.reports);
        dispatchData({
          type: 'Add_Modal_Data',
          payload: {
            'azureID': res.userDetails.azureID,
            'firstName': res.userDetails.firstName,
            'lastName': res.userDetails.lastName,
            'userEmail': res.userDetails.userEmail,
            'clientIdList': res.clients.map((c:any)=>
              c.clientID),
            'reportIdList': res.reports.map((r:any)=>
              r.departmentReportID),
            'programIdList': res.programs.map((p:any)=>
              p.programID),
            'departmentIdList': res.departments.map((d:any)=>
              d.departmentID),
            'userRoleName': res.userDetails.userRoleName,
          },
        });
        const selectedClient: any = [];
        res.clients.forEach((client:any)=>{
          selectedClient.push({
            ...client,
            name: client.clientName,
          });
        });
        setClientSelectedValue(selectedClient);
        const selectedDepartment: any = [];
        res.departments.forEach((department:any)=>{
          selectedDepartment.push({
            ...department,
            name: department.departmentName,
            id: department.departmentID,
          });
        });
        setDepartmentSelectedValue(selectedDepartment);
        const selectedProgram: any = [];
        res.programs.forEach((program:any)=>{
          selectedProgram.push({
            ...program,
            name: program.programName,
          });
        });
        setProgramSelectedValue(selectedProgram);
        const selectedReport: any = [];
        res.reports.forEach((report:any)=>{
          selectedReport.push({
            ...report,
            label: report.departmentName + '-' + report.reportName,
            value: report.departmentReportID,
          });
        });
        setReportSelectedValue(selectedReport);
      });
    } else {
      setClientSelectedValue([]);
      setProgramSelectedValue([]);
      setDepartmentSelectedValue([]);
      setReportSelectedValue([]);
     
    }
    return () => {
      removeSelectedClientName();
      setUserLoginHistory(initialLoginHistory);
      setFormError((p: any) =>
        ({
          ...p,
          azureID:'',
          userEmail:'',
          userRoleName:'',
          departmentId: '',
          clientIdList: '',
          programIdList: '',
          reportIdList: '',
        }));
      dispatchData({
        type: 'Add_Modal_Data',
        payload: {
          'azureID': '',
          'firstName': '',
          'lastName': '',
          'userEmail': '',
          'clientIdList': [],
          'reportIdList': [],
          'programIdList': [],
          'departmentIdList': [],
          'userRoleName': '',
          'userType': '',
        },
      });
    };
  }, [ props.data ]);

  function onSelectClient(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];

    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) =>
      ({
        ...p,
        clientIdList: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push(s.clientID);
    });
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { clientIdList: data },
    });
  }

  function onRemoveClient(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
      if (modalData.userRoleName == 'Client Partner'){
        setFormError((p: any) =>
          ({
            ...p,
            clientIdList: 'Client required',
          }));
        }
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { clientIdList: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push(s.clientID);
      });
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { clientIdList: data },
      });
    }
  }

  function onSelectProgram(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) =>
      ({
        ...p,
        programIdList: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push(s.programID);
    });
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { programIdList: data },
    });
  }

  function onRemoveProgram(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
     
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { programIdList: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push(s.programID);
      });
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { programIdList: data },
      });
    }
  }

  function onSelectDepartment(selectedList: any, selectedItem: any) {
    
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) =>
      ({
        ...p,
        departmentIdList: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push(s.id);
    });
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { departmentIdList: data },
    });
  }

  function onRemoveDepartment(selectedList: any, removedItem: any) {
    
    if (selectedList.length <= 0) {
      if (modalData.userRoleName == 'Department Owner'){
        setFormError((p: any) =>
          ({
            ...p,
            reportIdList: 'Department required',
          }));
      }
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { departmentIdList: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push(s.id);
      });
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { departmentIdList: data },
      });
    }
  }


  function onSelectR(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) =>
      ({
        ...p,
        reportIdList: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push(s.departmentReportID);
    });
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { reportIdList: data },
    });
    setReportSelectedValue(selectedList);
  }

  function onRemoveR(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
      setFormError((p: any) =>
        ({
          ...p,
          reportIdList: 'User required',
        }));
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { reportIdList: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push(s.departmentReportID);
      });
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { reportIdList: data },
      });
    }
  }

  const getModalType = () => {
    if (props.data.header === 'Add User') {
      return 'add';
    } else if (props.data.header === 'Edit User') {
      return 'update';
    } else if (props.data.header === 'Copy User') {
      return 'copy';
    }
  };

  const validateForm = (modalData: any) => {
    let isValid = true;

    if (modalData.azureID.length <= 0) {
      isValid = false;
      setFormError((p: any) =>
        ({
          ...p,
          azureID: 'User required',
        }));
    }

    if (!modalData.userEmail) {
      isValid = false;
      setFormError((p: any) =>
        ({
          ...p,
          userEmail: 'Email is missing',
        }));
    }

    if (modalData.userRoleName.length <= 0) {
      isValid = false;
      setFormError((p: any) =>
        ({
          ...p,
          userRoleName: 'Role required',
        }));
    }

    if (modalData.userRoleName == 'Client Partner') {
      if (modalData.clientIdList.length <= 0) {
        isValid = false;
        setFormError((p: any) =>
          ({
            ...p,
            clientIdList: 'Client required',
          }));
      }
    }

    if (modalData.userRoleName == 'Department Owner') {
      if (modalData.departmentIdList.length <= 0) {
        isValid = false;
        setFormError((p: any) =>
          ({
            ...p,
            departmentIdList: 'Department required',
          }));
      }
    }

   

    return isValid;
  };

  const submit = () => {
    if (validateForm(modalData)) {
      if (props.data && props.data.currentRow) {
        props.data.submit({
          modalData,
          id: props.data.currentRow.usersID,
          type: getModalType(),
          loading: false,
        });
      } else {
        props.data.submit({
          modalData,
          type: getModalType(),
          loading: false,
        });
      }
    }
  };
  function transformDate(date: any) {
    if(date){
      const a = date.split('-');
      return `${a[2].slice(0, 2)}/${a[1]}/${a[0]}`;
    } else{
      return 'No Logins';
    }
  }

  const getFullName = (user: any) =>
    user.firstName + ' ' + user.lastName;

  const selectedValueDecorator = (val: any) =>
    (
      <small>{val}</small>
    );


  const optionValueDecorator = (val: any) =>
    (
      <p>{val}</p>
    );

  const optionValueDecorator2 = (val: any) => {
    return 'fghj';
  };
  

  const filterLocations = (search: string) => {
    setUserSearch(search);
    if (search) {
      const copy = _.clone(_nameList);
      const filtered = copy.filter((d: any) => {
        if (d && ((d.firstName && d.firstName.toLowerCase().includes(search.toLowerCase())) ||
          (d.lastName && (d.firstName+' '+ d.lastName).toLowerCase().includes(search.toLowerCase())) ||
          (d.email && d.email.toLowerCase().includes(search.toLowerCase())))) {
          return d;
        }
      });
      setNameList(filtered);
    } else {
      setNameList(_nameList);
    }
  };

  const removeSelectedClientName = () => {
    setSelectedClientName(null);
    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        'azureID': '',
        'firstName': '',
        'lastName': '',
        'userEmail': '',
        'userType': '',
      },
    });
  };

  function customArrow() {
    return (
      <div>
        <img
          src={require('../../../common/icons/arrow-down.svg')}
        />
      </div>

    );
  }

  const changeValue = (input: any) => {
    if (input && input.userRoleName) {
      setFormError((p: any) =>
        ({
          ...p,
          userRoleName: '',
          departmentIdList: '',
          clientIdList: '',
          programIdList: '',
          reportIdList: '',
        }));
    }
    setClientSelectedValue([]);
    setProgramSelectedValue([]);
    setDepartmentSelectedValue([]);
    setReportSelectedValue([]);
    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        ...input,
        clientIdList: [],
        programIdList: [],
        reportIdList: [],
        departmentIdList: [],
      },
    });
  };
  const onUserSelection = (item: any) => {
    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        'azureID': item.id,
        'firstName': item.firstName,
        'lastName': item.lastName,
        'userEmail': item.email,
        'userType': item.userType,
      },
    });
    setFormError(p =>
      ({
        ...p,
        'azureID': '',
        'firstName': '',
        'lastName': '',
        'userEmail': '',
      }));
  };
  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.data.header == 'View User' ?
            (
              <>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>First Name</div>
                    <div className={styles.view_department}>{modalData.firstName}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Last Name</div>
                    <div className={styles.view_department}>{modalData.lastName}</div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>Email Address</div>
                    <div className={styles.view_department}>{modalData.userEmail}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Role</div>
                    <div className={styles.view_department}>{modalData.userRoleName}</div>
                  </Col>
                </Row>
                {
                  (modalData.userRoleName == 'Super Admin' || !modalData.userRoleName) ?
                    null
                    : (
                      <>
                        <Row className="mt-3">
                          <p className={styles.text}>User Permission</p>
                        </Row>
                        <Row>
                          {
                          (modalData.userRoleName == 'Client Partner' || modalData.userRoleName == 'User') ? (
                            <Col md={6} >
                              <div className={styles.department_heading}>Clients</div>
                              <div className={styles.view_owners}>{
                                  clients.map((d:any)=>
                                    <p key={d.clientID}>{d.clientName}</p>)
                                }</div>
                            </Col>
                            )
                            : null
                        }
                          {
                          (modalData.userRoleName == 'User') ? (
                            <Col md={6} >
                              <div className={styles.department_heading}>Program</div>
                              <div className={styles.view_owners}>{
                                  programs.map((d:any)=>
                                    <p key={d.programID}>{d.programName}</p>)
                                }</div>
                            </Col>
                            ) : null
                        }
                          {
                          (modalData.userRoleName == 'Department Owner' || modalData.userRoleName == 'User') ? (
                            <Col md={6} className={styles.view_list}>
                              <div className={styles.department_heading}>Department</div>
                              <div className={styles.view_owners}>{
                                  departments.map((d:any)=>
                                    <p key={d.departmentID}>{d.departmentName}</p>)
                                }</div>
                            </Col>
                            )
                            : null
                        }
                          {
                          (modalData.userRoleName == 'User') ? (
                            <Col md={6} className={styles.view_list}>
                              <div className={styles.department_heading}>Reports</div>
                              <div className={styles.view_owners}>{
                                  reports.map((d:any)=>
                                    <p key={d.reportID}>{d.departmentName}-{d.reportName}</p>)
                                }</div>
                            </Col>
                            )
                            : null
                        }
                        </Row>
                      

                      </>
)
                }
                <Row className="mt-5">
                  <Col>
                    <div className={styles.department_heading}>User Logins</div>
                    <div className={styles.view_department}>{transformDate(userLoginHistory ? userLoginHistory.loginAttempt : '')}&nbsp;{userLoginHistory ? '(' + userLoginHistory.loginCount + ')' : ''}</div>
                  </Col>
                </Row>
              </>
            ) :
            (
              <Form>
                <Row className="mt-3">
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label className={styles.department_heading}>User
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        {selectedClientName && !hideselectedValue ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedClientName?.firstName + ' ' + (selectedClientName?.lastName?selectedClientName?.lastName:'')}</div>
                            
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput}
                          className={`${styles.LocationBarFormControl} ${styles.field}`}
                          type="text"
                          placeholder={selectedClientName ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions(true); setSelectedValue(false); setIsInputFocused(false);}, 500); }}
                          onFocus={() => { setSelectedValue(true); filterLocations(userSearchField); setIsInputFocused(true);}}
                          onChange={(e) => {
                            setHideOptions(false);
                            filterLocations(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions)
                              setHideOptions(false);
                            // else
                            //   setHideOptions(true);
                          }}
                        />
                        {!hideOptions ? (
                          nameList.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                nameList.map((item, index) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      onUserSelection(item);
                                      setSelectedClientName(item);
                                      setHideOptions(true);
                                      setSelectedValue(false);
                                      setUserSearch('');
                                      locationFilterInput.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.firstName + ' ' + (item.lastName?item.lastName:'')+' ('+item.email+')'}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No user found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                            (
                              <div className={styles.LocationBarRightIcon}>
                                <img
                                  src={require('../../../common/icons/arrow-down.svg')}
                                  role="none"
                                  alt="dropdown icon"
                                  onClick={() => {
                                    if (isInputFocused && hideOptions){
                                      setHideOptions(false);
                                    }
                                    else if( !isInputFocused && hideOptions){
                                      setHideOptions(false);
                                      locationFilterInput.current?.focus();
                                      setIsInputFocused(true);
                                    }
                                    else{
                                      setHideOptions(true);
                                      setIsInputFocused(false);
                                    }
                                  }}
                                />
                              </div>
                            ) : null
                        }
                      </div>
                      {
                        formError.azureID ? <span className={styles.error}>{formError.azureID}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label className={styles.department_heading}>Email Address
                      </Form.Label>
                      <Form.Control disabled value={modalData.userEmail} type="text" placeholder="Email Address" />
                      {
                        formError.userEmail ? <span className={styles.error}>{formError.userEmail}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label className={styles.department_heading}>Role
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Form.Select
                        className={styles.field}
                        placeholder="Select"
                        onChange={event => 
                        changeValue({ userRoleName: event.target.value })}
                        value={modalData.userRoleName}
                        aria-label="Default select example"
                      >
                        <option value="" disabled={true}>Select</option>
                        <option value="Super Admin">Super Admin</option>
                        <option value="User">User</option>
                        <option value="Client Partner">Client Partner</option>
                        <option value="Department Owner">Department Owner</option>

                      </Form.Select>
                      {
                        formError.userRoleName ? <span className={styles.error}>{formError.userRoleName}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                {
                  (modalData.userRoleName == 'Super Admin' || modalData.userRoleName == '') ?
                    null
                    : (
                      <>
                        <Row>
                          <p className={styles.text}>User Permission</p>
                        </Row>
                        {
                        (modalData.userRoleName == 'Client Partner' || modalData.userRoleName == 'User') ? (
                          <Row>
                            <Col>
                              <Form.Group className="mb-3" controlId="formBasicEmail4">
                                <Form.Label className={styles.department_heading}>Clients
                                  {(modalData.userRoleName == 'Client Partner') ? (<span className={styles.astrix}>*</span>) : null}
                                </Form.Label>
                                <Multiselect2
                                  customArrow={customArrow()}
                                  options={clientIdList} // Options to display in the dropdown
                                  avoidHighlightFirstOption={true}
                                  selectedValues={clientSelectedValue} // Preselected value to persist in dropdown
                                  onSelect={onSelectClient} // Function will trigger on select event
                                  onRemove={onRemoveClient} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  // singleSelect={true}
                                  className={styles.multi_select}
                                  showArrow={true}
                                  showCheckbox={true}
                                  placeholder="Select"
                                  selectedValueDecorator={val =>
                                    selectedValueDecorator(val)}
                                  optionValueDecorator={val =>
                                    optionValueDecorator(val)}
                                />
                                {
                                  formError.clientIdList ? <span className={styles.error}>{formError.clientIdList}</span> : ''
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                        )
                          : null
                      }
                        {
                        (modalData.userRoleName == 'User') ? (
                          <Row>
                            <Col>
                              <Form.Group className="mb-3" controlId="formBasicEmail5">
                                <Form.Label className={styles.department_heading}>Programs
                                 
                                </Form.Label>
                                <Multiselect2
                                  customArrow={customArrow()}
                                  options={programIdList} // Options to display in the dropdown
                                  avoidHighlightFirstOption={true}
                                  selectedValues={programSelectedValue} // Preselected value to persist in dropdown
                                  onSelect={onSelectProgram} // Function will trigger on select event
                                  onRemove={onRemoveProgram} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  // singleSelect={true}
                                  className={styles.multi_select}
                                  showArrow={true}
                                  showCheckbox={true}
                                  placeholder="Select"
                                  selectedValueDecorator={val =>
                                    selectedValueDecorator(val)}
                                  optionValueDecorator={val =>
                                    optionValueDecorator(val)}
                                />
                                {
                                  formError.programIdList ? <span className={styles.error}>{formError.programIdList}</span> : ''
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                        ) : null
                      }
                        {
                        (modalData.userRoleName == 'Department Owner' || modalData.userRoleName == 'User') ? (
                          <Row>
                            <Col>
                              <Form.Group className="mb-3" controlId="formBasicEmail6">
                                <Form.Label className={styles.department_heading}>Department
                                {(modalData.userRoleName == 'Department Owner') ? (<span className={styles.astrix}>*</span>) : null}
                                </Form.Label>
                                <Multiselect2
                                  ref={reportRef}
                                  customArrow={customArrow()}
                                  options={departmentIdList} // Options to display in the dropdown
                                  avoidHighlightFirstOption={true}
                                  selectedValues={departmentSelectedValue} // Preselected value to persist in dropdown
                                  onSelect={onSelectDepartment} // Function will trigger on select event
                                  onRemove={onRemoveDepartment} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  // singleSelect={true}
                                  className={styles.multi_select}
                                  showArrow={true}
                                  showCheckbox={true}
                                  placeholder="Select"
                                  selectedValueDecorator={val =>
                                    selectedValueDecorator(val)}
                                  optionValueDecorator={val =>
                                    optionValueDecorator(val)}
                                />
                                {
                                  formError.departmentIdList ? <span className={styles.error}>{formError.departmentIdList}</span> : ''
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                        )
                          : null
                      }
                        {
                        (modalData.userRoleName == 'User') ? (
                          <Row>
                            <Col>
                              <Form.Group className="mb-3" controlId="formBasicEmail7">
                                <Form.Label className={styles.department_heading}>Reports
                                  
                                </Form.Label>
                                <MultiSelect
                                  options={reportIdList}
                                  value={reportSelectedValue}
                                  onChange={onSelectR}
                                  labelledBy="Select"
                                />
                                {
                                  formError.reportIdList ? <span className={styles.error}>{formError.reportIdList}</span> : ''
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                        )
                          : null
                      }

                      </>
)
                }

              </Form>
            )
        }

      </Modal.Body>
      {
        (props.data.header != 'View User') ?
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              </div>
              <div>
                <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                  {getModalType() == 'add' ? 'Create' : getModalType() == 'update' ? 'Update' : 'Copy'}
                </Button>
              </div>
            </Modal.Footer>
          ) :
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">
                  <img src={require('../../../common/images/back_arrow.png')} alt="" /> &nbsp;Back</Button>
              </div>
            </Modal.Footer>
          )
      }
    </Modal>
  );
};

