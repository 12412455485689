import { Form, Row, Col, Button, InputGroup, Modal } from 'react-bootstrap';
import styles from './sftp-credential.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { useEffect, useReducer, useState } from 'react';
import { ConfirmationModal } from '@components/modals/confirmation-modal/confirmation-modal';
import { getSFTPDetail, postSFTPDetails } from '@http/requests/sftp';

function SftpCredential(props:any) {
    const sftpInitialData = {
        hostURL:'',
        userName:'',
        password: ''
      };

    const initialFormError: {
        hostURL: string,
        userName: string,
        password: string
      } = {
        hostURL: '',
        userName: '',
        password: '',
      };

    const sftpDataReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'Add_SFTP':
                return { ...state, ...action.payload };
            default:
                return state;
        }
    };

    const [ modalData, dispatchData ] = useReducer(sftpDataReducer, sftpInitialData);
    const [ formError, setFormError ] = useState(initialFormError);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showSuccessMsg, setShowSuccessMsg ] = useState('');

    useEffect(()=>{
        setFormError(initialFormError);
        fetchAndSetSftpData();
        
    },[props.activeKey, props.activeKeyTab]);


    const fetchAndSetSftpData = () => {
        getSFTPDetail().then((res:any)=>{
            if(res !=""){
                props.setSftpDataPresent(true);
                if(!(props.activeKey=='0' && props.activeEdit)){
                    props.setActiveEdit(false);
                }                    
                dispatchData({
                    type: 'Add_SFTP',
                      payload: res,
                  });
            }
            else{
                props.setActiveKey('0');
                props.setSftpDataPresent(false);
                props.setActiveEdit(true);
                dispatchData({
                    type: 'Add_SFTP',
                    payload: sftpInitialData,
                  });
            }
        });
    };


    const passwordShow = () => {
        setShowPassword(p =>
          !p);
      };
    
    const onHideConfirmation = () =>
        setShowConfirmation(false);
    const handleSuccessClose = () =>
      setShowSuccess(false);

    const validateForm = (modalData: any) => {
        let isValid = true;
    
        if ( !modalData.userName) {
          isValid = false;
          setFormError((p: any) => 
            ({
              ...p,
              userName: 'User Name required',
            }));
        }

        if ( !modalData.hostURL) {
            isValid = false;
            setFormError((p: any) => 
              ({
                ...p,
                hostURL: 'Host Server Address required',
              }));
        }

        if ( !modalData.password) {
        isValid = false;
        setFormError((p: any) => 
            ({
            ...p,
            password: 'password required',
            }));
        }

        // var regex = /^sftp:\/\/(?:\S+:\S+@)?(?:\S+@)?(?:[\w.-]+)(?::\d+)?(?:\/?|\/\S+)$/;
        // if( !formError.hostURL && !regex.test(modalData.hostURL.toLowerCase())){
        //     isValid = false;
        //     setFormError((p: any) => 
        //       ({
        //         ...p,
        //         hostURL: 'Invalid Host Server Address',
        //       }));
        // }

        if(!formError.password && modalData.password.length>50){
            isValid = false;
            setFormError((p: any) => 
              ({
                ...p,
                password: 'Password entered is too long',
              }));
        }

        if(!formError.userName && modalData.userName.length>100){
          isValid = false;
          setFormError((p: any) => 
            ({
              ...p,
              password: 'User Name entered is too long',
            }));
      }
        if(!formError.hostURL && modalData.hostURL.length>100){
          isValid = false;
          setFormError((p: any) => 
            ({
              ...p,
              hostURL: 'Host Server Address entered is too long',
            }));
      }
        return isValid;
    };
    
    const saveSFTP = (e: any) => {
        e.preventDefault();
        const data=modalData;
        data.userName= modalData.userName.trim();
        data.hostURL= modalData.hostURL.trim();
        data.password= modalData.password.trim();
    
        dispatchData({
          type: 'Add_SFTP',
            payload: data,
        });
    
        if (validateForm(data)) {
            setShowConfirmation(true);
        }
    };

    const submit= () => {
        setShowConfirmation(false);
        postSFTPDetails(modalData).then((res)=>{
          setShowSuccess(true);
          setShowSuccessMsg('SFTP Details added Successfully');
          setTimeout(() => {
            setShowSuccess(false);
            setShowSuccessMsg('');
          }, 1000);
          props.setActiveEdit(false);
          setShowPassword(false);
          fetchAndSetSftpData();
        })
        .catch((error) => {
          // Handle the error here
          fetchAndSetSftpData();
          setShowPassword(false);
        });
    }


  return (
    <>
        <div style={{margin: "2.5rem 1.5rem"}}>
            <Form>
            <Row className="mt-3">
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicHost">
                      <Form.Label className={styles.field_heading}>Host Server Address
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <div className={styles.LocationBar}>
                        <Form.Control
                          className={`${styles.field} ${!props.activeEdit ? styles.fieldDisabled : ''}`}
                          type="text"
                          placeholder="Host server address"
                          value={modalData.hostURL}
                          disabled={!props.activeEdit}
                          onChange={(e) => {
                            dispatchData({
                              type: 'Add_SFTP',
                              payload: { hostURL: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                hostURL: '',
                              }));
                          }}
                        />
                        {
                        formError.hostURL ? <span className={styles.error}>{formError.hostURL}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="formBasicConnection">
                      <Form.Label className={styles.field_heading}>User Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      
                      <div >
                        <Form.Control
                          className={`${styles.field} ${!props.activeEdit ? styles.fieldDisabled : ''}`}
                          type="text"
                          placeholder="Enter a user name"
                          value={modalData.userName}
                          disabled={!props.activeEdit}
                          onChange={(e) => {
                            dispatchData({
                              type: 'Add_SFTP',
                              payload: { userName: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                userName: '',
                              }));
                          }}
                        />
                        {
                        formError.userName ? <span className={styles.error}>{formError.userName}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicPass">
                      <Form.Label className={styles.field_heading}>Password
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        <InputGroup>
                            <Form.Control
                            className={`${styles.password} ${!props.activeEdit ? styles.fieldDisabled : ''}`}
                            type= {showPassword?"text":"password"}
                            placeholder="Password"
                            value={modalData.password}
                            disabled={!props.activeEdit}
                            onFocus={() => setIsFocused(true)} 
                            onBlur={() => setIsFocused(false)}
                            onChange={(e) => {
                                dispatchData({
                                type: 'Add_SFTP',
                                payload: { password: e.target.value },
                                });
                                setFormError(p => 
                                ({
                                    ...p,
                                    password: '',
                                }));
                            }}
                            />
                            <InputGroup.Text className={`${styles.eye} ${isFocused ? styles.focussed : ''}`}>
                                {showPassword ? <img onClick={passwordShow} src={require('../../../common/icons/eye-off.svg')} alt="" /> :
                                      <img onClick={passwordShow} src={require('../../../common/icons/eye.svg')} alt="" />}
                            </InputGroup.Text>
                        </InputGroup>
                        {
                        formError.password ? <span className={styles.error}>{formError.password}</span> : ''
                        }
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
            </Form>
            {props.activeEdit && (<div className={styles.footer}>
                <Button onClick={()=>{props.setActiveEdit(false); fetchAndSetSftpData();}} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              
                <Button  onClick={saveSFTP} className={globalStyle.footerAdd_btn} variant="primary">
                  {props.sftpDataPresent? 'Save': 'Add' }
                </Button>
            </div>)}
        </div>


        <ConfirmationModal submit={submit} show={showConfirmation} onHide={onHideConfirmation} message="You want to save the SFTP Details?"/>
        <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SftpCredential;