import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  ProSidebar,
  Menu,
  SubMenu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import './sidebar.css';
import { getDashboardSections } from '@http/requests/dashboard';
import { USER_ROLE } from '@constants';
import { getLoginRecord } from '@http/requests';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';
import {  setLocalStorageItem } from '@utils';
import { store } from '@redux/store';
import { setUserRole } from '@redux/actions';
import {
   Button, Modal
} from 'react-bootstrap';
import globalStyle from '../../common/styles/globalStyle.scss';

export const Sidebar = () => {
  const history = useHistory();
  const [itemList, setItemList] = useState([]);
  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
    const path = updatedList[0].sectionID + '_' + updatedList[0].sectionName;
    history.push('/dashboard/' + path);
  };

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [matchingPath, setMatchingPath] = useState('');
  const [opennme, setopennme] = useState(false);
  const [dashboardHighlight, setDashboardHighlight] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  
  const location = useLocation();
  const [refreshSections, setRefreshSections] = useState('');

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [sections, setSections] = useState([]);

  const [ roleChange, setRoleChange ] = useState(false);

  const role: string | null = useSelector(({ USER }: RootState) =>
    USER.userRole);
  const userRole = useRef<string|null>(role);

  const handleRoleChangeClose = () =>
    setRoleChange(false);


  useEffect(()=>{
    setIsAdmin(role == 'Super Admin'? true : false);
    if(userRole.current!=null && role!=userRole.current){
      setRoleChange(true);
      userRole.current=role;
      setTimeout(() => {
        setRoleChange(false);
        if(location.pathname=="/settings"){
          window.location.reload();
        }
        
      }, 2000);
    }
    else{
      userRole.current=role;
    }
  },[role]);

  useEffect(() => {
   
    getDashboardSections().then((res) => {

      setSections(res);
      setItemList(res);
      
    });
    setIsAdmin(role == 'Super Admin'? true : false);

    const account = JSON.parse(localStorage.getItem('account'));
    if (account && account.localAccountId) {
      getLoginRecord({ userId: account.localAccountId }).then((res: any) => {
        if(res && res.userRoleName && res.userRoleName!=role){
            store.dispatch(setUserRole(res.userRoleName));
            setLocalStorageItem(USER_ROLE, res.userRoleName);
            // if(role === 'Super Admin' || res.userRoleName==='Super Admin'){
            //   window.location.reload();
            // }
        }
      })
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [refreshSections]);

  useEffect(() => {
    if (windowDimensions.width <= 900) {
      setMenuCollapse(true);
    } else {
      setMenuCollapse(false);
    }

    const path = location.pathname.split('/');
    // setMatchingPath(path[1]);
    if (path.length == 2) {
      setMatchingPath(path[1]);
      if (
        path[1] == 'call-center-reports' ||
        path[1] == 'shipping-reports' ||
        path[1] == 'expense-reports' ||
        path[1] == 'operations-reports'
      ) {
        setopennme(true);
        setDashboardHighlight(true);
      } else {
      }
    } else {
      setMatchingPath(path[2]);
      if (
        path[2] == 'call-center-reports' ||
        path[2] == 'shipping-reports' ||
        path[2] == 'expense-reports' ||
        path[2] == 'operations-reports'
      ) {
        setopennme(true);
        setDashboardHighlight(true);
      } else {
      }
    }
    if(path.length == 3 && path[1] != "item-master" && path[2] != "exception"){
      setRefreshSections(path[2])
    }
  }, [windowDimensions, location]);

  const getActiveSection = () => {
    const path = location.pathname.split('/');
    if(path.length == 3 && path[1] == "item-master" && path[2] == "exception") return '';
    if (path.length == 3) {
      return path[2].split('_')[0];
    }
    return '';
  };

  const open = () => {
    setopennme((p) => !p);
  };

  


  return (
    <>
      {/* <BrowserRouter> */}
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}

        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className={`logotext ${menuCollapse ? 'logo__sm' : 'logo__lg'}`}>
              {/* small and big change using menucollapse state */}
              <p onClick={menuIconClick}>
                {menuCollapse ? (
                  <img className="logo-sm" src={require('../../common/icons/logo-sm.svg')} alt="" />
                ) : (
                  <img className="logo-lg" src={require('../../common/icons/logo.svg')} alt="" />
                )}
              </p>
            </div>
            <div className="line" />
          </SidebarHeader>
          <SidebarContent>
            <Menu>
              <SubMenu
                className={getActiveSection() ? 'super' : ''}
                title="My Reports"
                icon={
                  dashboardHighlight ? (
                    <img
                      className="icon"
                      src={require('../../common/images/dashboard.svg')}
                      alt=""
                    />
                  ) : (
                    <img
                      className="icon"
                      src={require('../../common/images/dashboard.png')}
                      alt=""
                    />
                  )
                }
              >
                <div className="App">
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided: any) => (
                        <div
                          className="list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {/* {
                            sections.map(s =>
                              
                            )
                          } */}
                          {itemList.map((s, index) => (
                            <Draggable
                              key={s.sectionID}
                              draggableId={s.sectionID.toString()}
                              index={index}
                            >
                              {(provided: any) => (
                                <div
                                  className="item-container"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <MenuItem
                                    active={s.sectionID == getActiveSection() ? true : false}
                                    className={
                                      s.sectionID == getActiveSection() ? 'submenu' : 'inactive'
                                    }
                                  >
                                    <NavLink
                                      className={
                                        s.sectionID == getActiveSection() ? 'active_submenu' : ''
                                      }
                                      exact
                                      to={`/dashboard/${s.sectionID}_${s.sectionName}`}
                                      
                                    >
                                      {s.sectionName}
                                    </NavLink>
                                  </MenuItem>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </SubMenu>
              <MenuItem
                className={matchingPath == 'reports' || matchingPath == 'report' ? 'super' : ''}
                icon={
                  <img className="icon" src={require('../../common/images/reports.png')} alt="" />
                }
              >
                <NavLink className="navlink" exact to="/reports">
                  Available Reports
                </NavLink>
              </MenuItem>
              <MenuItem
                className={matchingPath == 'automated-file-transfer' ? 'super' : ''}
                icon={
                  <img className="icon" src={require('../../common/images/sftpDetail.png')} alt="" />
                }
              >
                <NavLink className="navlink" exact to="/automated-file-transfer">
                  Automated File Transfer
                </NavLink>
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter className="footer">
            <Menu>
              <MenuItem
                className={matchingPath == 'settings' ? 'super' : ''}
                icon={
                  <img className="icon" src={require('../../common/images/setting.png')} alt="" />
                }
              >
                <NavLink className="navlink" exact to={'/settings'}>
                  Settings
                </NavLink>
              </MenuItem>
              {isAdmin? 
                (<>
                  <MenuItem
                    className={ (matchingPath == 'item-master' || matchingPath == 'exception') ? 'super' : ''}
                    icon={
                      <img className="icon" src={require('../../common/images/shipTemp.png')} alt="" />
                    }
                  >
                    <NavLink className="navlink" exact to="/item-master">
                      Ship Temp Configurations
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className={matchingPath == 'pharmacy-holidays' ? 'super' : ''}
                    icon={
                      <img className="icon" src={require('../../common/images/calendarIcon.png')} alt="" />
                    }
                  >
                    <NavLink className="navlink" exact to={'/pharmacy-holidays'}>
                      Pharmacy Holidays
                    </NavLink>
                  </MenuItem>
                </>):(<></>)}
              <MenuItem
                className={matchingPath == 'logout' ? 'super' : ''}
                icon={
                  <img className="icon" src={require('../../common/images/logout.png')} alt="" />
                }
              >
                <NavLink className="navlink" exact to="/logout">
                  Logout
                </NavLink>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>



      <Modal contentClassName="modal_content" dialogClassName="moo" show={roleChange} onHide={handleRoleChangeClose}>

        <Modal.Body className="modal_body">
          <div>
            <img  className="success_img" src={require('../../common/icons/warning.svg')} alt="" />
          </div>
          <br />
          <div >
            <h3>Your Role has been changed.</h3>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};