import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Form, Button, Tooltip, OverlayTrigger, Modal
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { PharmacyHolidaysModal } from '@components/modals/pharmacy-holidays-modal';
import { USER_ROLE } from '@constants';

import {
  getHolidayList, addPharmacyHoliday, editPharmacyHoliday, deletePharmacyHoliday
} from '../../http/requests';

import styles from './pharmacy-holidays.scss';
import globalStyle from '../../common/styles/globalStyle.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

function PharmacyHolidays() {
  const [ holidayList, setHolidayList ] = useState([]);
  const [ holidayId, setHolidayId ] = useState(null);
  const [ modalShow, setModalShow ] = useState(false);
  const [ mode, setMode ] = useState("");
  const [ isTooltipVisible, setIsTooltipVisible ] = useState(false);
  const maximumLimit = 24;
  const account = JSON.parse(localStorage.getItem('account'));
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ deletePayload, setDeletePayload ] = useState({});
  const [ isAdmin, setIsAdmin ] = useState(false);
  

  const tooltip = (
    <Tooltip id="tooltip" style={{fontSize: "14px"}}>
        Maximum limit of {maximumLimit} is reached
    </Tooltip>
  );


  const role: string = useSelector(({ USER }: RootState) =>
    USER.userRole) || 'User';
  
  useEffect(() => {
    //const role: string = localStorage.getItem(USER_ROLE) || 'User';
    
    if(role == 'Super Admin') {
      setIsAdmin(true);
      getPharmacyHolidays();
    } else setIsAdmin(false);
  }, [role]);
  
  const getPharmacyHolidays = () => {
    getHolidayList().then((res: any) => setHolidayList(res));
  }

  const editHoliday = (data: any) => {
    setHolidayId(data.id);
    setMode("Edit");
    setModalShow(true);
  }

  const addHoliday = () => {
    setHolidayId(null);
    setMode("Add");
    setModalShow(true);
  }

  const handleSuccessClose = () =>
    setShowSuccess(false);

  const successPopUp = (type: string) => {
    setShowSuccessMsg(`Holiday ${type} Successfully`);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setShowSuccessMsg('');
    }, 1000);
  }

  const handleClose = () => {
    setShowDeleteModal(false);
  }

  const submitAction = (payload: any) => {
    payload.userEmail = account.username;
    if(mode == "Edit") editPharmacyHoliday(payload).then((res) => {
      getPharmacyHolidays();
      successPopUp("Updated");
    }).catch((err) => {console.log(err)});
    else if(mode == "Add") addPharmacyHoliday(payload).then((res) => {
      getPharmacyHolidays();
      successPopUp("Created");
    }).catch((err) => {console.log(err)});

  }

  const deleteHoliday = (data: any) => {
    const payload = {id: data.id, userEmail: account.username};
    setDeletePayload(payload);
    setShowDeleteModal(true);
  }

  const deleteHolidayConfirm = () => {
    if(deletePayload)
      deletePharmacyHoliday(deletePayload).then((res) => {
        getPharmacyHolidays();
        setDeletePayload({});
        successPopUp("Deleted");
      }).catch((err) => {console.log(err)});
    setShowDeleteModal(false);
  }

  const columns = [
    {
      dataField: 'date',
      text: 'DATE',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
      sortFunc: (a: string, b: string, order: string) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
      
        if (order === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
    },
    {
      dataField: 'holiday',
      text: 'HOLIDAY',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '24rem' }
    },
    {
      dataField: '',
      text: 'ACTIONS',
      formatter: (cell: any, row: any) => (
        <div>
          <img
            style={{ cursor: 'pointer', marginRight: '1.25rem' }}
            src={require('../../common/images/edit-primary.png')}
            alt=""
            onClick={ () => editHoliday(row) }
          />
          <img
            style={{ cursor: 'pointer', marginLeft: '1.25rem' }}
            src={require('../../common/images/delete.png')}
            alt=""
            onClick={ () => deleteHoliday(row) }
          />
        </div>
        ),  
      style: { width: '12rem' },
    },
  ];

  return (
    <>
      {isAdmin? (
        <>
        <PharmacyHolidaysModal
          id = {holidayId}
          show = {modalShow}
          holidaylist = {holidayList}
          mode = {mode}
          onHide = {()=>setModalShow(false)}
          onSubmit = {(payload: any) => submitAction(payload)}
        />
        <div id={styles.department}>
          <div className={styles.head_section}>
            <OverlayTrigger
                placement="left"
                overlay={tooltip}
                show={isTooltipVisible && holidayList.length >= maximumLimit}
              >
              <Button onClick={() => {holidayList.length < maximumLimit? addHoliday() : {}}} className={styles.add_btn} variant="primary" onMouseEnter={() => (holidayList.length >= maximumLimit)? setIsTooltipVisible(true):{}}
                      onMouseLeave={() => isTooltipVisible? setIsTooltipVisible(false):{}}>Add</Button>
            </OverlayTrigger>
          </div>
          <div className={styles.table}>
            <BootstrapTable
              bootstrap5
              bordered={false}
              trClassName={styles.customRow}
              keyField="id"
              data={holidayList}
              columns={columns}
              headerClasses={styles.fixed_header}
            />
          </div>
        </div>
        <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showDeleteModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modal_body}>
            <div className={styles.modal_body}>
              <div>
                <img src={require('../../common/icons/warning.svg')} alt="" />
              </div>
              <div className={styles.modal_text}>
                This will delete the Holiday from the application.
              </div>
              <div className={styles.modal_text}>
                Do you want to proceed
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer bsPrefix={globalStyle.footerDelete}>
            <div>
              <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
            </div>
            <div>
              <Button onClick={deleteHolidayConfirm} className={globalStyle.footerAdd_btn} variant="primary">
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

          <Modal.Body className={styles.modal_body}>
            <div>
              <img className={styles.success_img} src={require('../../common/images/success.png')} alt="" />
            </div>
            <br />
            <div >
              <h3>{showSuccessMsg}</h3>
            </div>
          </Modal.Body>
        </Modal>
        </>
      ) : (<></>)}
    </>
  );
}

export default PharmacyHolidays;
