import styles from './report-scheduling-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { useEffect, useReducer, useRef, useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { getFrequency, getReportPages, getReports, getSFTPActiveUsers } from '@http/requests/sftp';
import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';


export const ReportSchedulingModal = (props: any) => {

  const initialData = {
    'azureId': '',
    'reportId': '',
    'frequencyId': '',
    'reportPages': [],
  };

  const initialFormError: {
    azureId: string,
    reportId: string,
    frequencyId: string,
    reportPages: string,
  } = {
    'azureId': '',
    'reportId': '',
    'frequencyId': '',
    'reportPages': ''
  };

  const dataReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'Add_Modal_Data':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };


  const [ frequencyList, setFrequencyList ] = useState([]);
  const [ nameList, setNameList ] = useState([]);
  const [ _nameList, _setNameList ] = useState([]);
  const [ reportNameList, setReportNameList ] = useState([]);
  const [ _reportNameList, _setReportNameList ] = useState([]);
  const [ reportPagesList, setReportPagesList ] = useState([]);
  const [ reportPagesSelectedValue, setReportPagesSelectedValue ] = useState([]);

  const [ selectedUser, setSelectedUser ] = useState(null);
  const [ hideselectedValue, setSelectedValue ] = useState(true);
  const [ hideOptions, setHideOptions ] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [ userSearchField, setUserSearch ] = useState('');
  const locationFilterInput = useRef<HTMLInputElement>(null);

  const [ selectedReport, setSelectedReport ] = useState(null);
  const [ reportSearchField, setReportSearch ] = useState('');
  const [ hideselectedValue2, setSelectedValue2 ] = useState(true);
  const [ hideOptions2, setHideOptions2 ] = useState(true);
  const [isInputFocused2, setIsInputFocused2] = useState(false);
  const locationFilterInput2 = useRef<HTMLInputElement>(null);

  const reportPagesRef = useRef();

  const [ modalData, dispatchData ] = useReducer(dataReducer, initialData);
  const [ formError, setFormError ] = useState(initialFormError);
  const [reportPagesKey, setReportPagesKey] = useState(0);

  useEffect(() => {
    if(props.data.activeTab){
      getFrequency().then((res: any)=>{
        setFrequencyList(res);
      });
    }
    
    if(props.data.activeTab=='user'){
      fetchAndSetUsersList();
    }
    // else if(props.data.activeTab=='admin'){
    //   fetchAndSetReportsList();
    // }

    if (props.data && props.data.currentRow){
      const data: any = [];
      props.data.currentRow.reportPageList.forEach((r:any)=>{
        data.push(
          r.reportPageID
        );
      });
      if(props.data.activeTab=='user'){
        setSelectedUser(props.data.currentRow.user);
        dispatchData({
          type: 'Add_Modal_Data',
          payload: {
            'azureId': props.data.currentRow.user.azureID,
            'reportId': props.data.currentRow.reportID,
            'frequencyId': props.data.currentRow.frequency.id,
            'reportPages': data,
          }
        });
      }
      else{
        dispatchData({
          type: 'Add_Modal_Data',
          payload: {
            'azureId': "",
            'reportId': props.data.currentRow.reportID,
            'frequencyId': props.data.currentRow.frequency.id,
            'reportPages': data,
          }
        });
      }

      getReportPages(props.data.currentRow.reportID).then((res: any)=>{
        setReportPagesList(res);
      });

      setSelectedReport({'reportID':props.data.currentRow.reportID , 'reportName' : props.data.currentRow.reportName});
      setReportPagesSelectedValue(props.data.currentRow.reportPageList);

      setSelectedValue(false);
      setSelectedValue2(false);
    }

    return ()=>{
      setReportNameList([]);
      _setReportNameList([]);
      setReportPagesList([]);
      setReportPagesSelectedValue([]);
      setSelectedUser(null);
      setSelectedReport(null);

      setFormError(initialFormError);
      dispatchData({
        type: 'Add_Modal_Data',
        payload: initialData
      });
    }
  
  }, [ props.data]);

  const fetchAndSetUsersList = () => {
    getSFTPActiveUsers().then((res:any) => {
      _setNameList(res);
      if(locationFilterInput.current){
        if (locationFilterInput.current.value) {
          const copy = res;
          const search=locationFilterInput.current.value;
          setUserSearch(search);
          const filtered = copy.filter((d: any) => {
            if (d && ((d.firstName && d.firstName.toLowerCase().includes(search.toLowerCase())) ||
              (d.lastName && d.lastName.toLowerCase().includes(search.toLowerCase())) ||
              (d.userEmail && d.userEmail.toLowerCase().includes(search.toLowerCase())))) {
              return d;
            }
          });
          setNameList(filtered);
        } else {
          setNameList(res);
        }
      }
      else{
        setNameList(res);
      }
    });
  };

  const fetchAndSetReportsList = (frequencyId:number,userId: string="") => {
    getReports(frequencyId,userId).then((res:any) => {
      _setReportNameList(res);
      if(locationFilterInput2.current){
        if (locationFilterInput2.current.value) {
          const copy = res;
          const search=locationFilterInput2.current.value;
          setReportSearch(search);
          const filtered = copy.filter((d: any) => {
            if (d && (d.reportName && d.reportName.toLowerCase().includes(search.toLowerCase())))
              {
              return d;
              }
          });
          setReportNameList(filtered);
        } else {
          setReportNameList(res);
        }
      }
      else{
        setReportNameList(res);
      }
    });
  };




  const getModalType = () => {
    if (props.data.header === 'Add Report') {
      return 'add';
    } else if (props.data.header === 'Edit Report') {
      return 'update';
    }
  };



  const validateForm = (modalData: any) => {
    let isValid = true;

    if (props.data.activeTab=='user' && (!modalData.azureId)) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          azureId: 'User required',
        }));
    }

    if (!modalData.reportId) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          reportId: 'Report required',
        }));
    }

    if (!modalData.frequencyId) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          frequencyId: 'Frequency required',
        }));
    }


    if (modalData.reportPages.length <=0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          reportPages: 'Report Page required',
        }));
    }
    return isValid;
  };

  const submit = () => {
    if (validateForm(modalData)) {
      if (props.data && props.data.currentRow) {

        const data: any = {
          reportId:modalData.reportId,
          frequency:modalData.frequencyId,
          ReportPages:modalData.reportPages
        };
        props.data.submit({
          data,
          azureId:modalData.azureId,
          type: getModalType(),
        });
      } else {
        const data: any = {
          reportId:modalData.reportId,
          frequency:modalData.frequencyId,
          ReportPages:modalData.reportPages
        };

        props.data.submit({
          data,
          azureId:modalData.azureId,
          type: getModalType()
        });
      }
    }
  };

  const onUserSelection = (item: any) => {
    if(props.data.activeTab=='user' && modalData.frequencyId){
      fetchAndSetReportsList(modalData.frequencyId,item.azureID);
    }
    clearReportsAndPages();
    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        'azureId': item.azureID,
      },
    });

    setFormError(p =>
      ({
        ...p,
        'azureId': ''
      }));
  };

  const onFrquencySelection = (event: any) => {
    if(props.data.activeTab=='user' && modalData.azureId){
      fetchAndSetReportsList(event.target.value,modalData.azureId);
    }
    else if(props.data.activeTab=='admin'){
      fetchAndSetReportsList(event.target.value);
    }
    if(getModalType() == 'add' && modalData.frequencyId==1){
      clearReportsAndPages();
    }

    dispatchData({
      type: 'Add_Modal_Data',
      payload: { frequencyId: event.target.value },
    });

    setFormError(p =>
      ({
        ...p,
        'frequencyId': ''
      }));
  };

  const onReportSelection = (item: any) => {
    getReportPages(item.reportID).then((res: any)=>{
      setReportPagesList(res);
    });

    //clear report pages input when we change report
    setReportPagesKey(p=>p+1);
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { reportPages: [] },
    });
    setFormError((p: any) => 
      ({
        ...p,
        reportPages: '',
      }));
    
    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        'reportId': item.reportID,
      },
    });

    setFormError(p =>
      ({
        ...p,
        'reportId': ''
      }));
  };

  const filterUsers = (search: string) => {
    setUserSearch(search);
    if (search) {
      const copy = _.clone(_nameList);
      const filtered = copy.filter((d: any) => {
        if (d && ((d.firstName && d.firstName.toLowerCase().includes(search.toLowerCase())) ||
          (d.lastName && (d.firstName+' '+ d.lastName).toLowerCase().includes(search.toLowerCase())) ||
          (d.email && d.email.toLowerCase().includes(search.toLowerCase())))) {
          return d;
        }
      });
      setNameList(filtered);
    } else {
      setNameList(_nameList);
    }
  };

  const filterReports = (search: string) => {
    setReportSearch(search);
    if (search) {
      const copy = _.clone(_reportNameList);
      const filtered = copy.filter((d: any) => {
        if (d && (d.reportName && d.reportName.toLowerCase().includes(search.toLowerCase())))
          {
          return d;
          }
      });
      setReportNameList(filtered);
    } else {
      setReportNameList(_reportNameList);
    }
  };

  const clearReportsAndPages = () => {
    setReportPagesKey(p=>p+1);
    dispatchData({
      type: 'Add_Modal_Data',
      payload: { reportPages: [] },
    });
    setFormError((p: any) => 
      ({
        ...p,
        reportPages: '',
      }));

    dispatchData({
      type: 'Add_Modal_Data',
      payload: {
        'reportId': '',
      },
    });

    setFormError(p =>
      ({
        ...p,
        'reportId': ''
      }));
      setSelectedReport(null);
      setReportSearch('');
      locationFilterInput2.current.value = '';

  };

  function onSelectR(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        reportPages: '',
      }));
    
    const data: any = [];
    selectedList.forEach((r:any)=>{
      data.push(
        r.reportPageID
      );
    });

    dispatchData({
      type: 'Add_Modal_Data',
      payload: { reportPages: data },
    });
  }

  function onRemoveR(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          reportPages: 'Report Pages required',
        }));
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { reportPages: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((r:any)=>{
        data.push(
          r.reportPageID
        );
      });
      dispatchData({
        type: 'Add_Modal_Data',
        payload: { reportPages: data },
      });
    }
  }

  function customArrow() {
    return (
      <div>
        <img
          src={require('../../../common/icons/arrow-down.svg')}
        />
      </div>

    );
  }

  const selectedValueDecorator = (val: any) => 
    (
      <small>{val}</small>
    );
  

  const optionValueDecorator = (val: any) => 
    (
      <p>{val}</p>
    );

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <Form>
                {props.data.activeTab=='user' &&(<Row className="mt-3">
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                  <Form.Group className="mb-3" controlId="formBasicUser">
                      <Form.Label className={styles.department_heading}>User
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        {selectedUser && !hideselectedValue ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedUser?.firstName + ' ' + (selectedUser?.lastName?selectedUser?.lastName:'')}</div>     
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput}
                          className={`${styles.field}`}
                          type="text"
                          placeholder={selectedUser ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions(true); setSelectedValue(false); setIsInputFocused(false);}, 500); }}
                          onFocus={() => { setSelectedValue(true); filterUsers(userSearchField); setIsInputFocused(true);}}
                          onChange={(e) => {
                            setHideOptions(false);
                            filterUsers(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions)
                              setHideOptions(false);
                          }}
                        />
                        {!hideOptions ? (
                          nameList.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                nameList.map((item, index) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      onUserSelection(item);
                                      setSelectedUser(item);
                                      setHideOptions(true);
                                      setSelectedValue(false);
                                      setUserSearch('');
                                      locationFilterInput.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.firstName + ' ' + (item.lastName?item.lastName:'')+' ('+item.userEmail+')'}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No user found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                            (
                              <div className={styles.LocationBarRightIcon}>
                                <img
                                  src={require('../../../common/icons/arrow-down.svg')}
                                  role="none"
                                  alt="dropdown icon"
                                  onClick={() => {
                                    if (isInputFocused && hideOptions){
                                      setHideOptions(false);
                                    }
                                    else if( !isInputFocused && hideOptions){
                                      setHideOptions(false);
                                      locationFilterInput.current?.focus();
                                      setIsInputFocused(true);
                                    }
                                    else{
                                      setHideOptions(true);
                                      setIsInputFocused(false);
                                    }
                                  }}
                                />
                              </div>
                            ) : null
                        }
                      </div>
                      {
                        formError.azureId ? <span className={styles.error}>{formError.azureId}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>)}
                <Row className="mt-4">
                <Col >
                  <Form.Group className="mb-3" controlId="formBasicFrequency">
                      <Form.Label className={styles.department_heading}>Frequency
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Form.Select
                        className={styles.field}
                        placeholder="Select"
                        onChange={event => onFrquencySelection(event)}
                        value={modalData.frequencyId}
                        aria-label="Default select example"
                      >
                        <option value="" disabled={true}>Select</option>
                        {frequencyList.map((item, index) => (
                        <option key={item.id} value={item.id}>
                            {item.frequency}
                        </option>
                        ))}
                      </Form.Select>
                      {
                        formError.frequencyId ? <span className={styles.error}>{formError.frequencyId}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                  <Form.Group className="mb-3" controlId="formBasicReport">
                      <Form.Label className={styles.department_heading}>Report
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        {selectedReport && !hideselectedValue2 ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedReport?.reportName}</div>     
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput2}
                          className={`${styles.field}`}
                          type="text"
                          placeholder={selectedReport ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions2(true); setSelectedValue2(false); setIsInputFocused2(false);}, 500); }}
                          onFocus={() => { setSelectedValue2(true); filterReports(reportSearchField); setIsInputFocused2(true);}}
                          onChange={(e) => {
                            setHideOptions2(false);
                            filterReports(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions2)
                              setHideOptions2(false);
                          }}
                        />
                        {!hideOptions2 ? (
                          reportNameList.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                reportNameList.map((item, index) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      onReportSelection(item);
                                      setSelectedReport(item);
                                      setHideOptions2(true);
                                      setSelectedValue2(false);
                                      setReportSearch('');
                                      locationFilterInput2.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.reportName}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No report found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                            (
                              <div className={styles.LocationBarRightIcon}>
                                <img
                                  src={require('../../../common/icons/arrow-down.svg')}
                                  role="none"
                                  alt="dropdown icon"
                                  onClick={() => {
                                    if (isInputFocused2 && hideOptions2){
                                      setHideOptions2(false);
                                    }
                                    else if( !isInputFocused2 && hideOptions2){
                                      setHideOptions2(false);
                                      locationFilterInput2.current?.focus();
                                      setIsInputFocused2(true);
                                    }
                                    else{
                                      setHideOptions2(true);
                                      setIsInputFocused2(false);
                                    }
                                  }}
                                />
                              </div>
                            ) : null
                        }
                      </div>
                      {
                        formError.reportId ? <span className={styles.error}>{formError.reportId}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col >
                    <Form.Group className="mb-3" controlId="formBasicReportPages">
                        <Form.Label className={styles.department_heading}>Report Pages
                          <span className={styles.astrix}>*</span>
                        </Form.Label>
                        <Multiselect
                        key={reportPagesKey}
                        ref={reportPagesRef}
                        customArrow={customArrow()}
                        options={reportPagesList} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={reportPagesSelectedValue}
                        onSelect={onSelectR}
                        onRemove={onRemoveR}
                        displayValue="pageName"
                        // singleSelect={true}
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                          {
                            formError.reportPages ? <span className={styles.error}>{formError.reportPages}</span> : ''
                          }
                      </Form.Group>
                    </Col>
                </Row>
              </Form>
      </Modal.Body>
          <Modal.Footer bsPrefix={globalStyle.footer}>
            <div>
              <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
            </div>
            <div>
              <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                {getModalType() == 'add' ? 'Add' : 'Update'}
              </Button>
            </div>
          </Modal.Footer>
    </Modal>
  );
};

