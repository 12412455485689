import { useEffect, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAnnouncements, postAnnouncement } from '@http/requests';
import { setAnnouncmentAction } from '@redux/actions';

import styles from './announcment.scss';

const Announcement = ({ activeTab, roleChange })=>{
  const history = useHistory();
  const dispatch = useDispatch();
  const [ inputField, setInputField ] = useState('');
  useEffect(()=>{
      getAnnouncements().then((res:any)=>{      
        setInputField(res);
      });
  }, [activeTab, roleChange]);
  const setAnnouncment = (input: string) => {
    setInputField(input);
  };

  useEffect(() => {
    if (activeTab != 'announcment') {
      getAnnouncements().then((res:any)=>{      
        setInputField(res);
      });
    }
  }, [ activeTab ]);
 
  const addAnnouncment = ()=>{
    postAnnouncement(
      { announcement: inputField }
    ).then(()=>{
      history.push('/');
      dispatch(setAnnouncmentAction(inputField));
    });
    setInputField('');
  };
  return (
    <>
      <div className={styles.filter}>
        <div className={styles.search}>
          <InputGroup>
            <Form.Control
              value={inputField}
              onChange={e =>
                  setAnnouncment(e.target.value)}
              className={styles.search_field}
                // as="textarea"
              type="text"
              placeholder="Enter announcement"
            />
          </InputGroup>
        </div>
        <div className={styles.buttons}>
          <Button onClick={addAnnouncment} className={styles.add_btn} variant="primary">
            <div>Edit</div>
            <div> Announcement</div>
          </Button>
                      
        </div>
      </div>
    </>
  );
};

export default Announcement;
